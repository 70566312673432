import React from "react";
import { useMediaQuery } from "react-responsive";
import AccompanyComponent from "../../Components/Accompanying/AccompanyComponent";

export default function Accompany() {
  const isMobile = useMediaQuery({
    query: "(max-width: 820px)",
  });

  return (
    <div className="custom-container">
      {isMobile ? (
        <div className="mobile-box">
          <AccompanyComponent />
        </div>
      ) : (
        <div className="backdrop">
          <div className="pc-box position-relative">
            <AccompanyComponent />
          </div>
        </div>
      )}
    </div>
  );
}
