import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null,
  reason: null,
};

export const clinicalProblems = createSlice({
  name: "clinicalProblemsSlice",
  initialState,
  reducers: {
    problems: (state, action) => {
      state.value = action.payload;
    },
    problemReason: (state, action) => {
      state.reason = action.payload;
    },
  },
});

export const { problems, problemReason } = clinicalProblems.actions;

export default clinicalProblems.reducer;
