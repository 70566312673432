import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { COUNTRIES, GENDERS } from "../../Config/constants";
import { useDispatch } from "react-redux";
import { loading } from "../../redux/slices/loadingSlice";
import { ecds } from "../../redux/slices/ecdsSlice";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Input from "react-phone-number-input/input";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useTranslation } from 'react-i18next';
import crossIcon from "../../Assets/Images/cal-close.png"

import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import "react-phone-input-2/lib/style.css";
import { personalDetail } from "../../redux/slices/basicDetialSlice";
import moment from "moment";
import { Popover } from "@mui/material";
import Calendar from "../Calendar";
import DatePickerUi from "../DatePickerUi";

export default function BasicDetailsComponent() {
  const [fName, setFName] = useState("");
  const [sName, setSName] = useState("");
  const [gender, setGender] = useState();
  const [dob, setDob] = useState();
  const [postCode, setPostCode] = useState("");
  const [contact, setContact] = useState("");
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [showInfoClass, setShowInfoClass] = useState(false);
  const [age, setAge] = useState("17");
  const [anchorEl, setAnchorEl] = useState(null);
  const [popup, setPopup] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("+44");
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [showPicker, setShowPicker] = useState(false);

  const basicDetail = useSelector((state) => state.basicDetail.booking);

  const dispatch = useDispatch();
  const alphabetRegex = new RegExp("^[a-zA-Z]+$");
  const numberRegex = new RegExp(`^[0-9]*$`);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const selectClick = () => {
    setOpen(!open);
    if (!open) {
      document.getElementById("myClass").style.color = "black";
    }
  };

  const selectChange = (e) => {
    document.getElementById("myClass").style.color = "white";
    setGender(e.target.value);
  };

  useEffect(() => {
    if (
      basicDetail.bookingFor === `${t('bookingForSomeOneElse')}` &&
      basicDetail.isBookingForChild
    ) {
      setShowInfoClass(true);
    }
  }, [basicDetail]);

  useEffect(() => {
    dispatch(loading({ percent: 0 }));
    dispatch(ecds(null));
  }, []);

  const formatDob = (date) => {
    let myDate = date.split("/");
    myDate.forEach((element, index) => {
      if (element.length < 2) {
        myDate[index] = `0${element}`;
      }
    });

    let m = myDate[0];
    let d = myDate[1];
    let y = myDate[2];
    return `${y}${m}${d}`;
  };

  const handleNext = async () => {
    if (!fName) {
      toast.error(`${t('enter_first_name')}`);
    } else if (!sName) {
      toast.error(`${t('enter_surname')}`);
    } else if (!gender || gender === "null") {
      toast.error(`${t('please_select_sex_assigned')}`);
    } else if (!dob) {
      toast.error(`${t('select_dob')}`);
    } else if (!postCode) {
      toast.error(`${t('enter_postcode')}`);
    } else if (
      !contact &&
      !(
        basicDetail.bookingFor === `${t('bookingForSomeOneElse')}` &&
        basicDetail.isBookingForChild
      )
    ) {
      toast.error(`${t('invalid_mobile_number')}`);
    } else if (
      basicDetail.bookingFor === `${t('bookingForMySelf')}` &&
      age < 15
    ) {
      return toast.error(
        `${t('under_15_warning')}`,
        "error"
      );
    } else {
      const num = `${selectedCountry}${contact}`;
      const validation = isValidPhoneNumber(num);
      if (
        validation === false &&
        !(
          basicDetail.bookingFor === `${t('bookingForSomeOneElse')}` &&
          basicDetail.isBookingForChild
        )
      ) {
        return toast.error(`${t('invalid_mobile_number')}`);
      } else {
        let obj = {
          firstname: fName,
          lastname: sName,
          gender,
          dob: formatDob(dob),
          postCode,
          phone: num,
          terms: true,
        };
        dispatch(personalDetail({ personalDetail: obj }));
        if (
          basicDetail.bookingFor === `${t('bookingForSomeOneElse')}` &&
          basicDetail.isBookingForChild
        ) {
          navigate("/child-relationship");
        } else if (
          basicDetail.bookingFor === `${t('bookingForSomeOneElse')}` &&
          !basicDetail.isBookingForChild
        ) {
          navigate("/person-relationship");
        } else if (
          basicDetail.bookingFor === `${t('bookingForMySelf')}` &&
          !basicDetail.isBookingForChild
        )
          navigate("/identifications-detail");
      }
    }
  };

  const checkAlphaRegex = (data) => {
    if (alphabetRegex.test(data) || data === "") {
      return true;
    } else {
      return false;
    }
  };

  const checkNumRegex = (data) => {
    if (numberRegex.test(data) || data === "" || data.charAt(0) === "+") {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setAge(moment().diff(dob, "years", true));
  }, [dob]);

  const handlePrevious = () => {
    navigate("/booking");
  };
  const CountrySelect = ({ value, onChange, labels, ...rest }) => (
    <>
      <select
        {...rest}
        value={value}
        onChange={(event) => onChange(event.target.value || undefined)}
      >
        <option value="">{labels["ZZ"]}</option>
        {getCountries().map((country) => (
          <option key={country} value={getCountryCallingCode(country)}>
            {labels[country]} +{getCountryCallingCode(country)}
          </option>
        ))}
      </select>
      <Input
        placeholder="Enter phone number"
        value={value}
        onChange={(event) => onChange(`${event.target.value}`)}
      />
    </>
  );

  useEffect(() => {
    if (search === "") {
      setFilteredData([]);
    } else {
      let data = [...COUNTRIES].filter((e) =>
        e.country.toLowerCase().includes(search)
      );
      setFilteredData(data);
      console.log({ data });
    }
  }, [search]);

  const showPopup = Boolean(anchorEl);
  const id = showPopup ? "simple-popover" : undefined;

  const showDatePicker = () => {
    setShowPicker(!showPicker);
  }

  return (
    <div className="px-2 d-flex flex-column justify-content-center align-items-center">
      <img
        src={require("../../Assets/Images/logo.png")}
        className="logo-smaller"
        alt="logo"
      />
      <div className="arrow-left">
        <img
          className="arrow-left-img"
          src={require("../../Assets/Images/arrow-left.png")}
          alt="arrow"
          onClick={handlePrevious}
        />
      </div>
      <div className="heading mt-1">{t('welcome')}</div>
      <div className="info my-2">
        {t('In_order_to_registered')}
      </div>
      {/* {basicDetail.bookingFor === `${t('bookingForMySelf')}` && (
        <div className="info my-2">
          {t('In_order_to_registered')}
        </div>
      )}
      {basicDetail.bookingFor === `${t('bookingForSomeOneElse')}` &&
        basicDetail.isBookingForChild && (
          <div className="info my-2">
            {t('in_order_to_get_you_registered')}
          </div>
        )}
      {basicDetail.bookingFor === `${t('bookingForSomeOneElse')}` &&
        !basicDetail.isBookingForChild && (
          <div className="info my-2">
            {t('In_order_to_registered')}
          </div>
        )} */}

      <div
        className={`mt-2 ${showInfoClass && "info"
          } d-flex flex-column align-items-center`}
      >
        <Form.Control
          className="text-fields mb-2 content-color"
          placeholder={t('first_name')}
          value={fName}
          onChange={(e) => {
            // if (checkAlphaRegex(e.target.value) === true) {
            if (e.target.value.length < 31) {
              if (
                /^[a-zA-Z\u0600-\u06FF!@#\$%\^\&*\)\(+=._-]+$/.test(e.target.value || e.target.value === "")
              ) {
                let newFName = e.target.value.replace(
                  /(^|[\s-])\S/g,
                  function (match) {
                    return match.toUpperCase();
                  }
                );
                setFName(newFName);
                // }
              }
            }
          }}
        />

        <Form.Control
          className="text-fields mb-2 content-color"
          placeholder={t('last_name')}
          value={sName}
          // onFocus={startTimer}
          onChange={(e) => {
            // if (checkAlphaRegex(e.target.value) === true) {
            if (e.target.value.length < 31) {
              if (
                /^[a-zA-Z\u0600-\u06FF!@#\$%\^\&*\)\(+=._-]+$/.test(e.target.value || e.target.value === "")
              ) {
                let newFName = e.target.value.replace(
                  /(^|[\s-])\S/g,
                  function (match) {
                    return match.toUpperCase();
                  }
                );
                setSName(newFName);
                // }
              }
            }
            // }
          }}
        />

        <Form.Select
          className="colored-border trans-background mb-2 font-13 width-250 pd-10 selectField"
          id="myClass"
          onClick={selectClick}
          onChange={selectChange}
        >
          <option value={"null"}>{t('sex_assigned')}</option>
          {GENDERS().map((e, i) => (
            <option key={i} value={e.value}>
              {e.name}
            </option>
          ))}
        </Form.Select>
        {/*Calendar aslan*/}
        {/* <div className="cal-wrap">
          <Form.Control
            className="text-fields mb-2 color-white"
            placeholder="Date of birth"
            value={dob}
            onClick={() => showDatePicker()}
          />
          {
            showPicker === true ?
              <div className="calender-wrapper">
                <img src={crossIcon} alt="crossIcon" onClick={() => setShowPicker(false)} />
                <Calendar setDob={setDob} setShowPicker={setShowPicker} />
              </div>
              : ""
          }
        </div> */}
        <div className="cal-wrap">
          <Form.Control
            className="text-fields mb-2 content-color"
            placeholder={t('date_of_birth')}
            value={dob}
            onClick={() => showDatePicker()}
          />
          {
            showPicker === true ?
              <div className="calender-wrapper">
                {/* <img src={crossIcon} alt="crossIcon" onClick={() => setShowPicker(false)} /> */}
                <DatePickerUi setDob={setDob} setShowPicker={setShowPicker} />
              </div>
              : ""
          }
        </div>
        {/*Calendar aslan*/}

        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            open={show}
            showToolbar={false}
            value={dob}
            onChange={(newValue) => {
              setShow(false);
              setDob(newValue?.$d?.toLocaleDateString());
              setAge();
            }}
            maxDate={new Date()}
            renderInput={({ inputRef }) => (
              <Form.Group className="w-100">
                <InputGroup>
                  <Form.Control
                    className="text-fields mb-2 color-white"
                    ref={inputRef}
                    value={dob ? dob : null}
                    onClick={() => setShow(!show)}
                    placeholder="Date of birth"
                  />
                </InputGroup>
              </Form.Group>
            )}
          />
        </LocalizationProvider> */}

        <Form.Control
          className="text-fields mb-2 content-color"
          placeholder={t('postcode')}
          value={postCode}
          // onFocus={startTimer}
          onChange={(e) => {
            // if (checkNumRegex(e.target.value) === true) {
            if (e.target.value.length < 10) {
              setPostCode(e.target.value);
            }
            // }
          }}
        />

        {!(
          basicDetail.bookingFor === `${t('bookingForSomeOneElse')}` &&
          basicDetail.isBookingForChild
        ) && (
            <div className="d-flex">
              <div
                className="phone-input"
                onClick={(event) => {
                  setPopup(true);
                  setAnchorEl(event.currentTarget);
                  setSearch("");
                }}
              >
                <div className="country-selection">{selectedCountry} </div>{" "}
                <ArrowDropDownIcon fontSize="small" color="ffffff99" />
              </div>
              <Popover
                open={popup}
                anchorEl={anchorEl}
                onClose={() => setPopup(false)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <div className="p-3" style={{ height: "200px" }}>
                  <Form.Control
                    style={{
                      background: "white",
                      color: "black",
                    }}
                    placeholder="Search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                  {(filteredData && filteredData.length > 0
                    ? filteredData
                    : COUNTRIES
                  ).map((e) => (
                    <div
                      onClick={() => {
                        setPopup(false);
                        setSelectedCountry(`+${e.code}`);
                      }}
                      className="cursor-pointer my-1"
                      style={{ fontSize: "13px" }}
                    >{`${e.country} ( +${e.code} )`}</div>
                  ))}
                </div>
              </Popover>
              <Form.Control
                className="text-field2 content-color"
                style={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderRight: "none !important",
                }}
                placeholder={t('phone_number')}
                type="number"
                value={contact}
                onChange={(e) => {
                  if (checkNumRegex(e.target.value) === true) {
                    if (e.target.value.length < 15) {
                      setContact(e.target.value);
                    }
                  }
                }}
              />
            </div>
          )}

        <button className="button-next mt-3 m-auto" onClick={handleNext}>
          <span>{t('next')}</span>
        </button>
      </div>
    </div>
  );
}
