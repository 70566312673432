import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { signupData } from "../../redux/slices/signupSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import loader from "../../Assets/Images/loader.gif";
import { useTranslation } from 'react-i18next';
import { ConnectingAirportsOutlined } from "@mui/icons-material";

const IdentificationsComponent = () => {
  const [identification, setIdentification] = useState("");
  const [otherIdentification, setOtherIdentification] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const detailData = useSelector((state) => state.basicDetail);
  const { version } = useSelector(state => state.basicDetail)

  const { bookingFor, isBookingForChild } = detailData.booking;
  const { bookingByDetails, relationshipToPerson } = detailData;
  const { firstname, lastname, phone, gender, dob, postCode, terms } =
    detailData.personalDetail;
  const handleNext = async () => {
    if (!identification) {
      return toast.error(`${t('please_selec_answer')}`);
    }
    if (identification === "Other" && !otherIdentification) {
      return toast.error(`${t('please_enter_your_answer')}`);
    }
    const hospitalId = localStorage.getItem("hospitalId");
    const obj = {
      bookingFor,
      isBookingForChild,
      bookingByDeatils: bookingByDetails,
      relationshipToPerson,
      firstname,
      lastname,
      dob,
      postCode,
      phone: phone ? phone : "",
      gender,
      identifyGender:
        identification !== "Other" ? identification : otherIdentification,
      terms,
      hospital: hospitalId,
    };
    if (
      bookingFor === `${t('bookingForSomeOneElse')}` &&
      isBookingForChild
    ) {
      delete obj.phone;
    }
    const obj1 = {
      bookingFor,
      isBookingForChild: false,
      firstname,
      lastname,
      gender,
      dob,
      postCode,
      phone,
      terms: true,
      identifyGender: identification,
      hospital: hospitalId ? hospitalId : "",
    };
    dispatch(signupData(bookingFor === `${t('bookingForSomeOneElse')}` ? obj : obj1))
    navigate("/emergency-contact", { state: { signupData: bookingFor === `${t('bookingForSomeOneElse')}` ? obj : obj1 } })
  };

  const handlePrevious = () => {
    if (bookingFor === `${t('bookingForMySelf')}` && !isBookingForChild) {
      navigate("/basic-details");
    } else if (
      bookingFor === `${t('bookingForSomeOneElse')}` &&
      !isBookingForChild
    ) {
      navigate("/person-detail");
    } else if (
      bookingFor === `${t('bookingForSomeOneElse')}` &&
      isBookingForChild
    ) {
      navigate("/child-detail");
    }
  };

  return (
    <div className="ecds-container">
      <div className="content-div">
        <img
          src={require("../../Assets/Images/logo.png")}
          className="logo-smaller"
          alt="logo"
        />
        <div className="arrow-left">
          <img
            className="arrow-left-img"
            src={require("../../Assets/Images/arrow-left.png")}
            alt="arrow"
            onClick={handlePrevious}
          />
        </div>
        {showLoader && (
          <img className="loader_div" src={loader} alt="loading..." />
        )}
        <div className="question">{version === "firstPerson" ? t('how_do_you') : version === "paediatric" ? t('how_does_child_identify') : t('how_does_the_person_identify')}</div>
        <div className="h-custom">
          <div
            onClick={() => setIdentification(`${t('male')}`)}
            className={
              identification === `${t('male')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('male')}
          </div>
          <div
            onClick={() => setIdentification(`${t('female')}`)}
            className={
              identification === `${t('female')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('female')}
          </div>
          <div
            onClick={() => setIdentification(`${t('bigender')}`)}
            className={
              identification === `${t('bigender')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('bigender')}
          </div>
          <div
            onClick={() => setIdentification(`${t('gender_fluid')}`)}
            className={
              identification === `${t('gender_fluid')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('gender_fluid')}
          </div>
          <div
            onClick={() => setIdentification(`${t('non_binary')}`)}
            className={
              identification === `${t('non_binary')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('non_binary')}
          </div>
          <div
            onClick={() => setIdentification(`${t('transgender_male')}`)}
            className={
              identification === `${t('transgender_male')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('transgender_male')}
          </div>
          <div
            onClick={() => setIdentification(`${t('transgender_female')}`)}
            className={
              identification === `${t('transgender_female')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('transgender_female')}
          </div>
          <div
            onClick={() => setIdentification(`${t('other')}`)}
            className={
              identification === `${t('other')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('other')}
          </div>
          {identification === `${t('other')}` && (
            <>
              <div className="question">{version === "firstPerson" ? t('you_identity') : version === "paediatric" ? t('how_child_identify') : t('they_identity')}</div>
              <div className="">
                <Form.Control
                  value={otherIdentification}
                  onChange={(e) => {
                    if (e.target.value.length < 31) {
                      setOtherIdentification(e.target.value);
                    }
                  }}
                  as="textarea"
                  placeholder={t('enter_your_answer_here')}
                  className="trans-background no-resize color-white font-14 custom-field h-100p"
                />
              </div>
            </>
          )}
        </div>
      </div>
      <button className="button-ecds-next br py-2" onClick={handleNext}>
        <span>{t('next')}</span>
      </button>
    </div>
  );
};

export default IdentificationsComponent;
