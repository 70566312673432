import React, { useEffect, useState } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import "./Assets/Css/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import Layout from "./Layout/index";

function App() {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(localStorage.getItem('i18nextLng') || 'en')
  const isLightMode = useSelector(state => state.isLightMode?.value)

  const languageMap = {
    en: { label: "English", dir: "ltr", active: true },
    ar: { label: "العربية", dir: "rtl", active: false }
  };
  useEffect(() => {
    document.body.dir = languageMap[selected]?.dir;
  }, [selected])
  return (
    <div className={isLightMode && "dark-color"}>
      <Layout />
    </div>
  );
}

export default App;
