import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: 40,
};

export const slice = createSlice({
  name: "screenTimerSlice",
  initialState,
  reducers: {
    screenTimer: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { screenTimer } = slice.actions;

export default slice.reducer;
