import { FormControl, Input, InputAdornment, InputLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { toastify } from "../../HelperFunctions/toastify";
import { loading } from "../../redux/slices/loadingSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ecds } from "../../redux/slices/ecdsSlice";
import { useNavigate } from "react-router-dom";
import Popup from "./Popup";
import { useMediaQuery } from "react-responsive";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers";
import { postEcds } from "../../APIs/ecds";
import { toast } from "react-toastify";
import crossIcon from "../../Assets/Images/cal-close.png";
import Calendar from "../Calendar";
import DatePickerUi from "../DatePickerUi";
import loader from "../../Assets/Images/loader.gif";
import {
  problems,
  problemReason,
} from "../../redux/slices/clinicalProblemsSlice";
import { clinicalProblems } from "../../APIs/earProblem";
import { useTranslation } from 'react-i18next';

export default function EcdsComponent({
  question,
  setQuestion,
  answers,
  questions,
}) {
  const [bool, setBool] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [selected, setSelected] = useState();
  const [phrase, setPhrase] = useState("");
  const [search, setSearch] = useState("");
  const [popup, setPopup] = useState(false);
  const [reason, setReason] = useState("");
  const [date, setDate] = useState();
  const [dateObject, setDateObject] = useState();
  const [dateShow, setDateShow] = useState(false);
  const [time, setTime] = useState();
  const [timeObject, setTimeObject] = useState();
  const [timeShow, setTimeShow] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  var prev_ecds = useSelector((state) => state.ecds.value);
  const userData = useSelector((state) => state.userData.value);
  const { version } = useSelector(state => state.basicDetail)
  const usingLanguage = localStorage.getItem('i18nextLng')
  const { t } = useTranslation();

  useEffect(() => {
    if (search === "") setFilteredData([]);
    else {
      filtering();
    }
  }, [search]);

  useEffect(() => {
    var myDiv = document?.getElementById("scroll-div");
    if (myDiv) myDiv.scrollTop = 0;

    handleLoading();
    setSearch("");

    if (prev_ecds && prev_ecds?.length > 0) {
      let copy = [...prev_ecds];
      let myData = copy.find((e) => e.question === question.index);
      let dateData = copy.find((e) => e.question === question.index + 1);
      let timeData = copy.find((e) => e.question === question.index + 2);
      if (myData) {
        if (myData.type === "Bool") {
          setBool(myData.answer);
        } else if (myData.type === "Reason") {
          if (myData.answer !== "Injury") {
            setReason(myData.answer);
          } else {
            setReason(myData.answer || "");
            setDateObject(dateData.answer || "");
            setTimeObject(timeData.answer || "");
          }
        } else {
          setSelected(myData.answer);
        }
      } else {
        setBool();
        setSelected();
      }
    }
  }, [question]);

  const saveHistory = () => {
    if (question.type === "Bool") {
      if (prev_ecds) {
        let _index = prev_ecds.findIndex((e) => e.question === question.index);
        let _data = [...prev_ecds];
        if (_index > -1) {
          _data[_index] = {
            question: question.index,
            ques: question.name,
            type: question.type,
            answer: bool,
          };
          dispatch(ecds(_data));
        } else {
          _data.push({
            question: question.index,
            ques: question.name,
            type: question.type,
            answer: bool,
          });
          dispatch(ecds(_data));
        }
      } else {
        dispatch(
          ecds([
            {
              question: question.index,
              ques: question.name,
              type: question.type,
              answer: bool,
            },
          ])
        );
      }
    } else if (question.type === "Reason") {
      if (prev_ecds) {
        let _index1 = prev_ecds.findIndex((e) => e.question === question.index);
        let _index2 = _index1 + 1;
        let _index3 = _index1 + 2;
        let _data = [...prev_ecds];
        if (_index1 !== -1) {
          if (reason === "Illness") {
            _data[_index1] = {
              question: question.index,
              type: question.type,
              answer: reason,
            };

            _data = _data.filter((e) => e.question < 19);
          } else {
            _data[_index1] = {
              question: question.index,
              ques: question.name,
              type: question.type,
              answer: reason,
            };
            _data[_index2] = {
              question: question.index + 1,
              ques: t('injury_date_title'),
              type: question.type,
              answer: dateObject,
            };
            _data[_index3] = {
              question: question.index + 2,
              ques: t('injury_time_status'),
              type: question.type,
              answer: timeObject,
            };
          }
          dispatch(ecds(_data));
        } else {
          _data.push(
            {
              question: question.index,
              ques: question.name,
              type: question.type,
              answer: reason,
            },
            {
              question: question.index + 1,
              ques: t('injury_date_title'),
              type: question.type,
              answer: dateObject,
            },
            {
              question: question.index + 2,
              ques: t('injury_time_status'),
              type: question.type,
              answer: timeObject,
            }
          );
          dispatch(ecds(_data));
        }
      }
      // else {
      //   dispatch(
      //     ecds([
      //       {
      //         question: question.index,
      //         type: question.type,
      //         answer: reason,
      //       },
      //       {
      //         question: question.index + 1,
      //         type: question.type,
      //         answer: dateObject,
      //       },
      //       {
      //         question: question.index + 2,
      //         type: question.type,
      //         answer: timeObject,
      //       },
      //     ])
      //   );
      // }
    } else {
      if (prev_ecds) {
        let _index = prev_ecds.findIndex((e) => e.question === question.index);
        let _data = [...prev_ecds];
        if (_index > -1) {
          _data[_index] = {
            question: question.index,
            ques: question.name,
            type: question.type,
            answer: selected,
            option: phrase,
          };
          dispatch(ecds(_data));
        } else {
          _data.push({
            question: question.index,
            ques: question.name,
            type: question.type,
            answer: selected,
            option: phrase,
          });
          dispatch(ecds(_data));
        }
      } else {
        dispatch(
          ecds([
            {
              question: question.index,
              ques: question.name,
              type: question.type,
              answer: selected,
              option: phrase,
            },
          ])
        );
      }
    }
  };

  const showDatePicker = () => {
    setShowPicker(!showPicker);
  }

  const getClassName = () => {
    let ind = question.index;
    if (ind === (9 || 8 || 13 || 15 || 20 || 21 || 22 || 23)) return "h-custom";
    else return "answer";
  };

  const handleLoading = () => {
    if (question.index < 25) {
      let p = question.index + 1;
      if (p === 2) {
        dispatch(loading({ percent: 0 }));
      } else if (p === 3) {
        dispatch(loading({ percent: 2 }));
      } else if (p === 4) {
        dispatch(loading({ percent: 5 }));
      } else if (p === 5) {
        dispatch(loading({ percent: 7 }));
      } else if (p === 6) {
        dispatch(loading({ percent: 10 }));
      } else if (p === 7) {
        dispatch(loading({ percent: 12 }));
      } else if (p === 8) {
        dispatch(loading({ percent: 15 }));
      } else if (p === 9) {
        dispatch(loading({ percent: 17 }));
      } else if (p === 10) {
        dispatch(loading({ percent: 20 }));
      } else if (p === 11) {
        dispatch(loading({ percent: 22 }));
      } else if (p === 12) {
        dispatch(loading({ percent: 25 }));
      } else if (p === 13) {
        dispatch(loading({ percent: 27 }));
      } else if (p === 14) {
        dispatch(loading({ percent: 30 }));
      } else if (p === 15) {
        dispatch(loading({ percent: 32 }));
      } else if (p === 16) {
        dispatch(loading({ percent: 35 }));
      } else if (p === 17) {
        dispatch(loading({ percent: 37 }));
      } else if (p === 18) {
        dispatch(loading({ percent: 40 }));
      } else if (p === 19) {
        dispatch(loading({ percent: 42 }));
      } else if (p === 20) {
        dispatch(loading({ percent: 40 }));
      } else if (p === 21) {
        dispatch(loading({ percent: 42 }));
      } else if (p === 22) {
        dispatch(loading({ percent: 45 }));
      } else if (p === 23) {
        dispatch(loading({ percent: 47 }));
      } else if (p === 1) {
        dispatch(loading({ percent: 0 }));
      }
    } else {
      if (question.index === 25) {
        dispatch(loading({ percent: 16 }));
      }
    }
  };

  const saveEcds = async () => {
    if (prev_ecds && question.index !== 18) {
      let _index = prev_ecds.findIndex((e) => e.question === question.index);
      let _data = [...prev_ecds];
      if (_index > -1) {
        _data[_index] = {
          question: question.index,
          ques: question.name,
          type: question.type,
          answer: selected,
          option: phrase,
        };
        dispatch(ecds(_data));
      } else {
        _data.push({
          question: question.index,
          ques: question.name,
          type: question.type,
          answer: selected,
          option: phrase,
        });
        dispatch(ecds(_data));
      }
    }

    const getData = (index) => {
      return prev_ecds.find((e) => e.question === index);
    };

    let data = {
      Cough: getData(1)?.answer,
      Cough_ques: getData(1)?.ques,
      Temperature: getData(2)?.answer,
      Temperature_ques: getData(2).ques,
      Covid19: getData(3)?.answer,
      Covid19_ques: getData(3).ques,
      Chemotherapy: getData(4)?.answer,
      Chemotherapy_ques: getData(4).ques,
      Radiotherapy: getData(5)?.answer,
      Radiotherapy_ques: getData(5)?.ques,
      Immunosuppressed: getData(6)?.answer,
      Immunosuppressed_ques: getData(6)?.ques,
      Military: getData(7)?.answer,
      Military_ques: getData(7)?.ques,
      Ethnic_Category: {
        code: getData(8)?.answer,
        answer: getData(8)?.option,
      },
      Ethnic_Category_ques: getData(8)?.ques,
      Spoken_Language: {
        code: getData(9)?.answer,
        answer: getData(9)?.option,
      },
      Spoken_Language_ques: getData(9)?.ques,
      Interpreter_Language_Status: getData(10)?.answer,
      Interpreter_Language_Status_ques: getData(10)?.ques,
      Interpreter_Language: {
        code: getData(11)?.answer,
        answer: getData(11)?.option,
      },
      Interpreter_Language_ques: getData(11)?.ques,
      Accommodation_Status: {
        code: getData(12)?.answer,
        answer: getData(12)?.option,
      },
      Accommodation_Status_ques: getData(12)?.ques,
      Attendance_Activity: {
        code: getData(13)?.answer,
        answer: getData(13)?.option,
      },
      Attendance_Activity_ques: getData(13)?.ques,
      Arrival_Mode: {
        code: getData(14)?.answer,
        answer: getData(14)?.option,
      },
      Arrival_Mode_ques: getData(14)?.ques,
      Addendance_Sources_Status: getData(15)?.answer,
      Addendance_Sources_Status_ques: getData(15)?.ques,
      Addendance_Sources: {
        code: getData(16)?.answer,
        answer: getData(16)?.option,
      },
      Addendance_Sources_ques: getData(16)?.ques,
      Support_Communicating_Status: getData(25)?.answer,
      Support_Communicating_Status_ques: getData(25)?.ques,
      Support_Communicating: {
        code: getData(26)?.answer,
        answer: getData(26)?.option,
      },
      Support_Communicating_ques: getData(26)?.ques,
      Outside_UK: getData(17)?.answer,
      Outside_UK_ques: getData(17)?.ques,
      Injury: reason === "Injury" ? true : false,
      DiseaseType: reason,
      Injury_ques: getData(18)?.ques,
      hospital: localStorage.getItem("hospitalId"),
    };

    if (reason === "Injury") {
      data = {
        ...data,
        Injury_date: getData(19)?.answer,
        Injury_date_ques: getData(19)?.ques,
        Injury_time: new Date(getData(20)?.answer).toLocaleTimeString(),
        Injury_time_ques: getData(20)?.ques,

        Place_Of_Injury: {
          code: getData(21)?.answer,
          answer: getData(21)?.option,
        },
        Place_Of_Injury_ques: getData(21)?.ques,
        Injury_Intent: {
          code: getData(22)?.answer,
          answer: getData(22)?.option,
        },
        Injury_Intent_ques: getData(22)?.ques,
        Injury_Acrtivity_Status: {
          code: getData(23)?.answer,
          answer: getData(23)?.option,
        },
        Injury_Acrtivity_Status_ques: getData(23)?.ques,
        Injury_Activity_Type: {
          code: selected,
          answer: phrase,
        },
        Injury_Activity_Type_ques: getData(24)?.ques,
      };
    }
    setShowLoader(true)
    const response = await postEcds(data);
    if (response?.status !== "Fail") {
      const gender =
        userData?.user.gender.charAt(0).toUpperCase() +
        userData?.user.gender.slice(1);
      const payload = {
        gender: gender,
        injuryOrIllness: reason,
        lang: usingLanguage
      };

      const problemsData = await clinicalProblems(payload);
      console.error({ problemsData });
      if (problemsData) {
        dispatch(problems(problemsData));
        dispatch(problemReason(payload));
        setShowLoader(false)
        navigate("/triage");
      }
    }
  };

  const handleNext = async () => {
    if (question.index < 24) {
      let type = question.type;
      setFilteredData([]);

      if (type === "Bool") {
        if (bool !== undefined) {
          if (
            (question.index === 10 && bool === false) ||
            (question.index === 15 && bool === false)
          ) {
            let _question = questions.find(
              (e) => e.index === question.index + 2
            );
            setQuestion(_question);
          }
          else {
            let _question = questions.find(
              (e) => e.index === question.index + 1
            );
            setQuestion(_question);
          }

          saveHistory();
        } else {
          toast.error(`${t('please_selec_answer')}`);
        }

      }
      else if (type === "Ethnic_Category") {
        let _question = questions.find(
          (e) => e.index === 25
        );
        setQuestion(_question);
        saveHistory();
      }
      else if (type === "Reason") {
        if (!dateObject && reason === "Injury") {
          toast.error(`${t('select_date_of_injury')}`);
        } else if (!timeObject && reason === "Injury") {
          toast.error(`${t('select_time_of_injury')}`);
        } else {
          if (reason && reason !== "") {
            if (reason === "Illness" || reason === "other") {
              saveHistory();
              saveEcds();
            } else {
              let _question = questions.find(
                (e) => e.index === question.index + 3
              );
              setQuestion(_question);

              saveHistory();
              // setReason();
            }
          } else {
            toast.error(`${t('please_selec_answer')}`);
          }
          setDateObject();
          setTimeObject();
        }
      } else {
        if (selected || (date && dateObject)) {
          saveHistory();

          let _question = questions.find((e) => e.index === question.index + 1);
          setQuestion(_question);
        } else {
          toast.error(`${t('please_selec_answer')}`);
        }
      }
      // setBool();
      // setSelected();
    }
    else if (question.index === 25 || question.index === 26) {
      let type = question.type;
      setFilteredData([]);
      if (type === "Bool") {
        if (bool !== undefined) {
          if (question.index === 25 && bool === false) {
            let _question = questions.find(
              (e) => e.index === 9
            );
            setQuestion(_question);
            saveHistory();
          } else if (question.index === 25 && bool === true) {
            let _question = questions.find(
              (e) => e.index === 26
            );
            setQuestion(_question);
            saveHistory();
          }
        } else {
          toast.error(`${t('please_selec_answer')}`);
        }
      }
      else if (question.index === 26) {
        if (!selected)
          toast.error(`${t('please_selec_answer')}`);
        else {
          let _question = questions.find(
            (e) => e.index === 9
          );
          setQuestion(_question);
          saveHistory();
        }
      }
    }
    else if (question.index === 24) {
      if (!selected)
        toast.error(`${t('please_selec_answer')}`);
      else {
        saveEcds();
      }
    }
    // else if (question.index === 16) {
    //   if (!reason) {
    //     toastify("Please select one of the options below", "error");
    //   } else {
    //     console.log(1);
    //     // saveEcds();
    //   }
    // }
    setBool();
    setSelected();
  };

  const handlePrevious = () => {
    if (question.index === 8) {
      navigate("/pre-ecds-questions")
    }

    if (question.index === 1) {
      navigate("/pre-ecds");
      dispatch(loading({ percent: 0 }));
    } else if (question.index === 12) {
      let _answer = prev_ecds.find((e) => e.question === 11);
      if (_answer) {
        let _question = questions.find((e) => e.index === question.index - 1);
        setQuestion(_question);
      } else {
        let _question = questions.find((e) => e.index === question.index - 2);
        setQuestion(_question);
      }
    } else if (question.index === 17) {
      let _answer = prev_ecds.find((e) => e.question === 16);
      if (_answer) {
        let _question = questions.find((e) => e.index === question.index - 1);
        setQuestion(_question);
      } else {
        let _question = questions.find((e) => e.index === question.index - 2);
        setQuestion(_question);
      }
    }
    else if (question.index === 18) {
      let _answer = prev_ecds.find((e) => e.question === 17);
      if (_answer) {
        let _question = questions.find((e) => e.index === question.index - 1);
        setQuestion(_question);
      } else {
        let _question = questions.find((e) => e.index === question.index - 2);
        setQuestion(_question);
      }
    } else if (question.index === 21) {
      let _answer = prev_ecds.find((e) => e.question === 18);
      if (_answer) {
        let _question = questions.find((e) => e.index === question.index - 3);
        setQuestion(_question);
      } else {
        let _question = questions.find((e) => e.index === question.index - 2);
        setQuestion(_question);
      }
    } else if (question.index === 25) {
      let _answer = prev_ecds.find((e) => e.question === 8);
      if (_answer) {
        let _question = questions.find((e) => e.index === 8);
        setQuestion(_question);
      }
    }
    else if (question.index === 9) {
      let _answer = prev_ecds.find((e) => e.question === 25);
      if (_answer) {
        let _question = questions.find((e) => e.index === 25);
        setQuestion(_question);
      }
      let _answer1 = prev_ecds.find((e) => e.question === 26);
      if (_answer1) {
        let _question = questions.find((e) => e.index === 26);
        setQuestion(_question);
      }
    }
    else if (question.index === 26) {
      let _answer = prev_ecds.find((e) => e.question === 25);
      if (_answer) {
        let _question = questions.find((e) => e.index === 25);
        setQuestion(_question);
      }
    }
    else {
      let _question = questions.find((e) => e.index === question.index - 1);
      setQuestion(_question);
    }
  };

  const filtering = () => {
    let _answers = answers.filter(
      (e) =>
        e.Type === question.type &&
        e.Option?.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredData(_answers);
    if (_answers.length === 0) {
      toast.error(`${t('no_results_found')}`);
    }
  };

  const getPlaceholder = () => {
    if (question.type === "Interpreter_Language") return `${t('placholder_Search_language')}`;
    if (question.type === "Spoken_Language") return `${t('placholder_Search_language')}`;
    if (question.type === "Ethnic_Category") return `${t('search_ethnic_group')}`;
    if (question.type === "Accommodation_Status") return `${t('search')}`;
    if (question.type === "Attendance_Activity") return `${t('search')}`;
    if (question.type === "Arrival_Mode") return `${t('search')}`;
    if (question.type === "Addendance_Sources") return `${t('search')}`;
    if (question.type === "Place_Of_Injury") return `${t('search')}`;
    if (question.type === "Injury_Activity_Type") return `${t('search')}`;
    if (question.type === "Injury_Intent") return `${t('search')}`;
    if (question.type === "Injury_Acrtivity_Status") return `${t('search')}`;
    if (question.type === "Reason") return "Select reason";
    if (question.type === "Support_Communicating") return `${t('search')}`;
  };

  const getAnswer = () => {
    let type = question.type;

    if (type === "Bool") {
      return (
        <div className="answer overflow-hidden">
          <div
            onClick={() => setBool(true)}
            className={
              bool === true
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {question.index === 10
              ? `${t('need_interpreter')}`
              : `${t('yes')}`}
          </div>
          <div
            onClick={() => {
              if (question.index === 15) {
                let copy = [...prev_ecds];
                let myData = copy.filter((e) => e.question !== 16);

                dispatch(ecds(myData));
              }
              setBool(false);
            }}
            className={
              bool === false
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {question.index === 10 ? `${t('dont_need_interpreter')}` : `${t('no')}`}
          </div>
        </div>
      );
    } else if (type === "Reason") {
      return (
        <div className="answer overflow-hidden h-min">
          <div
            onClick={() => setReason("Illness")}
            className={
              reason === "Illness"
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('an_ilness')}
          </div>
          <div
            onClick={() => setReason("Injury")}
            className={
              reason === "Injury"
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('an_injury')}
          </div>
          <div
            onClick={() => setReason("other")}
            className={
              reason === "other"
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('something_else')}
          </div>
        </div>
      );
    } else if (type === "Date") {
      return (
        <div className="answer overflow-hidden">
          <div
            onClick={() => {
              setDateObject(
                new Date(
                  new Date().setDate(new Date().getDate() - 1)
                ).toLocaleDateString()
              );
              setDate(`${t('yesterday')}`);
            }}
            className={
              date === `${t('yesterday')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('yesterday')}
          </div>
          <div
            onClick={() => {
              setDateObject(new Date().toLocaleDateString());
              setDate(`${t('today')}`);
            }}
            className={
              date === `${t('today')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('today')}
          </div>
          <div
            onClick={() => {
              setDate("Custom");
            }}
            className={"inactive-option answer-div"}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                open={dateShow}
                showToolbar={false}
                value={dateObject}
                onChange={(newValue) => {
                  setDateShow(false);
                  setDateObject(newValue?.$d?.toLocaleDateString());
                }}
                maxDate={new Date()}
                renderInput={({ inputRef }) => (
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        style={{ borderWidth: 0 }}
                        className="trans-background p-0 color-white font-13"
                        // className="text-fields mb-2 color-white"
                        ref={inputRef}
                        value={
                          dateObject && date === "Custom" ? dateObject : null
                        }
                        onClick={() => setDateShow(!dateShow)}
                        placeholder={t('select_date')}
                      />
                      {/* {InputProps?.endAdornment} */}
                    </InputGroup>
                  </Form.Group>
                )}
              />
            </LocalizationProvider>
          </div>
        </div>
      );
    } else {
      return (
        <div className={getClassName()} id="scroll-div">
          {question.index !== 12 && question.index !== 13 && question.index !== 26 && (
            <FormControl className="answer-multiple">
              <Input
                disableUnderline
                onChange={(e) => setSearch(e.target.value)}
                style={{ fontSize: 12, marginTop: 3 }}
                sx={{ input: { color: "white" } }}
                value={search}
                placeholder={getPlaceholder()}
                startAdornment={
                  <InputAdornment position="start">
                    <img
                      src={require("../../Assets/Images/searchIcon.png")}
                      className="logo-smallest"
                    />
                  </InputAdornment>
                }
              />
            </FormControl>
          )}
          {(filteredData.length === 0 && search
            ? []
            : filteredData.length === 0
              ? answers
              : filteredData
          )
            ?.filter((e) => e.Type === question.type)
            ?.map((e) => (
              <div
                onClick={() => {
                  setSelected(e.Code);
                  setPhrase(e.Option);
                }}
                className={
                  selected === e.Code
                    ? "active-option answer-div"
                    : "inactive-option answer-div"
                }
              >
                {e.Option}
              </div>
            ))}
        </div>
      );
    }
  };

  return (
    <div className="ecds-container">
      <Popup show={popup} setShow={setPopup} />
      <div className="arrow-left">
        <img
          className="arrow-left-img"
          src={require("../../Assets/Images/arrow-left.png")}
          alt="arrow"
          onClick={handlePrevious}
        />
      </div>
      {showLoader && (
        <img className="loader_div" src={loader} alt="loading..." />
      )}
      {question.index === 18 && reason === "Injury" ? (
        <div className="content-div-4">
          <img
            src={require("../../Assets/Images/logo.png")}
            className="logo-smaller"
            alt="logo"
          />

          <div className="content-div-2">
            <div>
              <div className="question">{question.name}</div>
              {getAnswer()}
            </div>
            <div>
              <div className="question">{t('injury_date_title')}</div>
              <div
                className="answer overflow-hidden h-min"
                style={{ height: "min-content" }}
              >
                <div
                  onClick={() => {
                    setDateObject(new Date().toLocaleDateString());
                    setDate(`${t('today')}`);
                  }}
                  className={
                    date === `${t('today')}`
                      ? "active-option answer-div"
                      : "inactive-option answer-div"
                  }
                >
                  {t('today')}
                </div>
                <div
                  onClick={() => {
                    setDateObject(
                      new Date(
                        new Date().setDate(new Date().getDate() - 1)
                      ).toLocaleDateString()
                    );
                    setDate(`${t('yesterday')}`);
                  }}
                  className={
                    date === `${t('yesterday')}`
                      ? "active-option answer-div"
                      : "inactive-option answer-div"
                  }
                >
                  {t('yesterday')}
                </div>
                {/* <div
                  onClick={() => {
                    setDate("Custom");
                  }}
                  className={
                    date === "Custom"
                      ? "active-option answer-div"
                      : "inactive-option answer-div"
                  }
                > */}
                {/* <div className="cal-wrap"> */}
                <Form.Control
                  className="text-fields mb-2 color-white"
                  placeholder={t('select_date')}
                  value={dateObject}
                  onClick={() => showDatePicker()}
                />
                {
                  showPicker === true ?
                    // <div className="calender-wrapper calender-wrapper2">
                    //   <img src={crossIcon} alt="crossIcon" onClick={() => setShowPicker(false)} />
                    //   <Calendar setDob={setDateObject} setShowPicker={setShowPicker} />
                    // </div>
                    <div className="calender-wrapper  calender-wrapper2">
                      {/* <img src={crossIcon} alt="crossIcon" onClick={() => setShowPicker(false)} /> */}
                      <DatePickerUi setDob={setDateObject} setShowPicker={setShowPicker} />
                    </div>
                    : ""
                }
                {/* </div> */}
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      open={dateShow}
                      showToolbar={false}
                      value={dateObject}
                      onChange={(newValue) => {
                        setDateShow(false);
                        setDateObject(newValue?.$d?.toLocaleDateString());
                      }}
                      maxDate={new Date()}
                      renderInput={({ inputRef }) => (
                        <Form.Group>
                          <InputGroup>
                            <Form.Control
                              className={`trans-background p-0 color-white font-13 no-shadow ${date === "Custom" ? "c-blue" : "color-white"
                                }`}
                              style={{
                                borderWidth: 0,
                              }}
                              ref={inputRef}
                              value={
                                dateObject && date === "Custom"
                                  ? dateObject
                                  : null
                              }
                              readOnly
                              onClick={() => setDateShow(!dateShow)}
                              placeholder="Select date"
                            />
                          </InputGroup>
                        </Form.Group>
                      )}
                    />
                  </LocalizationProvider> */}
                {/* </div> */}
              </div>
            </div>
            <div>
              <div className="question">{t('injury_time_status')}</div>
              <div
                className="answer overflow-hidden h-min"
                style={{ height: "min-content" }}
              >
                {date !== `${t('yesterday')}` &&
                  dateObject === new Date().toLocaleDateString() && (
                    <div
                      onClick={() => {
                        let t = new Date(
                          new Date().setHours(new Date().getHours() - 1)
                        );

                        setTimeObject(t);

                        setTime("LastHour");
                      }}
                      className={
                        time === "LastHour"
                          ? "active-option answer-div"
                          : "inactive-option answer-div"
                      }
                    >
                      {t('in_the_last')}
                    </div>
                  )}
                <div
                  onClick={() => {
                    setTime("Custom");
                  }}
                  className={
                    time === "Custom"
                      ? "active-option answer-div"
                      : "inactive-option answer-div"
                  }
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      open={timeShow}
                      showToolbar={false}
                      ampmInClock={true}
                      value={
                        timeObject
                          ? new Date(timeObject)?.toLocaleTimeString
                          : null
                      }
                      onChange={(newValue) => {
                        setTimeObject(newValue?.$d);
                      }}
                      onClose={() => setTimeShow(false)}
                      onAccept={() => setTimeShow(false)}
                      renderInput={({ inputRef }) => (
                        <Form.Group>
                          <InputGroup>
                            <Form.Control
                              style={{ borderWidth: 0 }}
                              className={`trans-background p-0 color-white font-13 no-shadow ${time === "Custom" ? "c-blue" : "color-white"
                                }`}
                              readOnly
                              ref={inputRef}
                              value={
                                timeObject && time === "Custom"
                                  ? new Date(timeObject).toLocaleTimeString()
                                  : null
                              }
                              onClick={() => setTimeShow(!timeShow)}
                              placeholder={t('selecttime')}
                            />
                            {/* {InputProps?.endAdornment} */}
                          </InputGroup>
                        </Form.Group>
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={` ${question.index === 12 ? "content-div-3" : null
            } content-div`}
        >
          <img
            src={require("../../Assets/Images/logo.png")}
            className="logo-smaller"
            alt="logo"
          />

          <div className="question">
            {question.name}
            {question.name === t(version === "firstPerson" ? "is_ammo_" : "is_ammo") && (
              <img
                src={require("../../Assets/Images/info-icon.png")}
                style={{ width: 18 }}
                className="cursor-pointer"
                onClick={() => setPopup(true)}
              />
            )}
          </div>
          {getAnswer()}
        </div>
      )}
      <button className="button-ecds-next br py-2" onClick={handleNext}>
        <span>{t('next')}</span>
      </button>
    </div>
  );
}
