import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    booking: null,
    relationshipToPerson: null,
    bookingByDetails: null,
    personalDetail: null
};

export const counterSlice = createSlice({
    name: "basicDetailSlice",
    initialState,
    reducers: {
        booking: (state, action) => {
            state.booking = action.payload.booking;
        },
        relationWith: (state, action) => {
            state.relationshipToPerson = action.payload.relationshipToPerson;
        },
        bookingByDetails: (state, action) => {
            state.bookingByDetails = action.payload.bookingByDetails;
        },
        personalDetail: (state, action) => {
            state.personalDetail = action.payload.personalDetail;
        },
        version: (state, action) => {
            state.version = action.payload.version;
        }
    },
});

export const { booking, relationWith, bookingByDetails, personalDetail, version } = counterSlice.actions;

export default counterSlice.reducer;
