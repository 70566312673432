import * as React from "react";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { FaPercent } from "react-icons/fa";

function AirbnbThumbComponent(props) {
  const loading = useSelector((state) => state.loading.value);

  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="slider-percent">
        {`${loading?.percent}`}
        <FaPercent style={{ width: 7, height: 6, marginTop: 1 }} />
      </span>
    </SliderThumb>
  );
}

export default function ProgressSlider() {
  const isMobile = useMediaQuery({
    query: "(max-width: 820px)",
  });
  const loading = useSelector((state) => state.loading.value);

  const AirbnbSlider = styled(Slider)(({ theme }) => ({
    color: "#3a8589",
    height: 3,
    padding: "13px 0",
    "& .MuiSlider-thumb": {
      height: 28,
      width: 29,
      backgroundColor: "#fff",
      // border: "1px solid currentColor",
      "&:focus, &:hover, &.Mui-active": {
        boxShadow: "none",
        "@media (hover: none)": {
          boxShadow: "none",
        },
      },
      "& .slider-percent": {
        fontSize: 8,
        fontWeight: "700",
        border: "4px solid #004562",
        height: 28,
        width: 29,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        lineHeight: "5",
        color: "#004561",
      },
    },

    "& .MuiSlider-rail": {
      color: "white",
      height: 5,
      // opacity: 1,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "& .MuiSlider-track": {
      color: "red",
      borderRadius: "0px",
      // borderWidth: 0,
      height: 4,
    },
  }));
  return (
    <Box
      sx={{ width: isMobile ? 300 : 220 }}
      className="d-flex flex-row align-items-center"
    >
      <div className="d-flex align-items-center">
        <div className="slider-dot-1">
          <div className="slider-dot-2" />
        </div>
        <div
          style={{
            background: "red",
            height: 4,
            width: 15,
            // borderTop: "1px solid white",
            // borderBottom: "1px solid white",
          }}
        ></div>
      </div>
      <AirbnbSlider
        disableSwap
        components={{ Thumb: AirbnbThumbComponent }}
        value={loading ? loading?.percent : 0}
      />
      <div className="slider-dot-1">
        <div className="slider-dot-2" />
      </div>
    </Box>
  );
}
