import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null,
};

export const earProblemSlice = createSlice({
  name: "earProblemSlice",
  initialState,
  reducers: {
    earProblem: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { earProblem } = earProblemSlice.actions;

export default earProblemSlice.reducer;
