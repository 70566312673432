import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null,
};

export const pathwayQuestionsSlice = createSlice({
  name: "pathwayQuestionsSlice",
  initialState,
  reducers: {
    pathwayQuestions: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { pathwayQuestions } = pathwayQuestionsSlice.actions;

export default pathwayQuestionsSlice.reducer;
