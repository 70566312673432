import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "../slices/userSlice";
import loadingReducer from "../slices/loadingSlice";
import ecdsReducer from "../slices/ecdsSlice";
import earProblemReducer from "../slices/earProblemSlice";
import timerReducer from "../slices/timerSlice";
import { persistReducer, persistStore } from "redux-persist";
import basicDetialReducer from "../slices/basicDetialSlice";
import questionsReducer from "../slices/questionsSlice";
import problemTypeSlice from "../slices/problemTypeSlice";
import clinicalProblems from "../slices/clinicalProblemsSlice";
import pathwayQuestionsSlice from "../slices/pathwayQuestionsSlice";
import themeSlice from "../slices/themeSlice";
import storage from "redux-persist/lib/storage";
import screenTimerSlice from "../slices/screenTimerSlice";
import signupSlice from "../slices/signupSlice";

import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  userData: userReducer,
  loading: loadingReducer,
  ecds: ecdsReducer,
  earProblem: earProblemReducer,
  timer: timerReducer,
  basicDetail: basicDetialReducer,
  abdominalPainQues: questionsReducer,
  problemType: problemTypeSlice,
  clinicalProblems: clinicalProblems,
  pathwayQuestions: pathwayQuestionsSlice,
  isLightMode: themeSlice,
  screenTimer: screenTimerSlice,
  signup: signupSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
