import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { getEcdsData } from "../../APIs/ecds";
import EcdsComponent from "../../Components/Ecds/EcdsComponent";
import ProgressSlider from "../../Components/Shared/ProgressSlider";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";


export default function Ecds() {
  const { state } = useLocation()
  const questionsList = useSelector(state => state.ecds.questions)
  const [answers, setAnswers] = useState([]);
  const [question, setQuestion] = useState(questionsList[7] || []);
  const { version } = useSelector(state => state.basicDetail)
  const usingLanguage = localStorage.getItem('i18nextLng')
  const isMobile = useMediaQuery({
    query: "(max-width: 820px)",
  });

  const getAnswers = async () => {
    const response = await getEcdsData(version, usingLanguage);
    if (response) {
      setAnswers(response);
    }
  };


  useEffect(() => {
    getAnswers();
  }, []);

  return (
    <div className="custom-container">
      {isMobile ? (
        <div className="mobile-box-pe">
          <div className="slider">
            <ProgressSlider />
          </div>
          <EcdsComponent
            questions={questionsList}
            answers={answers}
            question={question}
            setQuestion={setQuestion}
          />
        </div>
      ) : (
        <div className="backdrop">
          <div className="pc-box-pe position-relative">
            <div className="slider">
              <ProgressSlider />
            </div>
            <EcdsComponent
              questions={questionsList}
              answers={answers}
              question={question}
              setQuestion={setQuestion}
            />
          </div>
        </div>
      )}
    </div>
  );
}
