import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function WelcomeComponent() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="d-flex align-items-center">
      <div className="text-center">
        <img
          src={require("../../Assets/Images/opto-logo.png")}
          className="w-75"
          alt="logo"
        />
        <p className="heading mb-0">Welcome</p>
        <p className="welcome-info">Check-in & Triage here</p>
        <button
          className="get-started-btn br py-2 w-50 mt-2"
          onClick={() => navigate("/language")}
        >
          <span>{t("Get Started")}</span>
        </button>
      </div>
      <p onClick={() => navigate("/commitment")} className="commitment_para">
        {t("our_data_commitment_to_you")}
      </p>
    </div>
  );
}
