import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { toastify } from "../../HelperFunctions/toastify";
import { loading } from "../../redux/slices/loadingSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { EAR_PROBLEM_QUESTIONS } from "../../Config/constants";
import { earProblem } from "../../redux/slices/earProblemSlice";
import { useNavigate } from "react-router-dom";
import { timer } from "../../redux/slices/timerSlice";
import { postEarProblem } from "../../APIs/earProblem";
import Popup from "./../AbdominalPain/Popup";

export default function EarProblemComponent() {
  const [selected, setSelected] = useState({});
  const [text, setText] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  var MyTimer = useSelector((state) => state.timer.value);

  var EarProblemState = useSelector((state) => state.earProblem.value);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [questionIndex, setQuestionIndex] = useState(0);
  const [popup, setPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  useEffect(() => {
    setCurrentQuestion(EAR_PROBLEM_QUESTIONS[questionIndex]);
    populateAnswers(EAR_PROBLEM_QUESTIONS[questionIndex]);
    handleLoading();
  }, [questionIndex]);

  useEffect(() => {
    dispatch(loading({ percent: 52 }));
  }, []);

  const populateAnswers = (value) => {
    let myData = EarProblemState?.find((e) => e.question === value.name);
    if (myData) {
      let _data = { name: myData.answer, p: myData.code };
      if (value.type === "options") setSelected(_data);
      else setText(myData.answer);
    } else {
      setSelected({});
      setText("");
    }
  };

  const getAnswerLayout = () => {
    if (currentQuestion?.type === "options") {
      return (
        <div
          className="custom-padding"
          style={{
            overflowY: questionIndex === 1 ? "scroll" : "hidden",
          }}
        >
          {currentQuestion?.options?.map((option) => (
            <div
              onClick={() => {
                setSelected(option);
              }}
              className={
                JSON.stringify(selected) === JSON.stringify(option)
                  ? "active-option answer-div"
                  : "inactive-option answer-div"
              }
            >
              {option?.name}
            </div>
          ))}
        </div>
      );
    } else if (currentQuestion?.type === "text") {
      return (
        <div>
          <Form.Control
            placeholder={"Enter your answer here"}
            as="textarea"
            rows={3}
            className="trans-background no-resize color-white font-14 custom-field"
            onChange={(e) => setText(e.target.value)}
            value={text}
          />
        </div>
      );
    }
  };

  const saveHistory = () => {
    if (EarProblemState) {
      let _index = EarProblemState.findIndex(
        (e) => e.question === currentQuestion.name
      );
      let _data = [...EarProblemState];
      if (_index > -1) {
        _data[_index] = {
          question: currentQuestion.name,
          answer: currentQuestion.type === "options" ? selected.name : text,
          code: currentQuestion.type === "options" ? selected.p : "P0",
        };
        dispatch(earProblem(_data));
      } else {
        _data.push({
          question: currentQuestion.name,
          answer: currentQuestion.type === "options" ? selected.name : text,
          code: currentQuestion.type === "options" ? selected.p : "P0",
        });
        dispatch(earProblem(_data));
      }
    } else {
      dispatch(
        earProblem([
          {
            question: currentQuestion.name,
            answer: currentQuestion.type === "options" ? selected.name : text,
            code: currentQuestion.type === "options" ? selected.p : "P0",
          },
        ])
      );
    }
  };

  const handleLoading = () => {
    if (questionIndex === 0) {
      dispatch(loading({ percent: 52 }));
    } else if (questionIndex === 1) {
      dispatch(loading({ percent: 54 }));
    } else if (questionIndex === 2) {
      dispatch(loading({ percent: 56 }));
    } else if (questionIndex === 3) {
      dispatch(loading({ percent: 58 }));
    } else if (questionIndex === 4) {
      dispatch(loading({ percent: 60 }));
    } else if (questionIndex === 5) {
      dispatch(loading({ percent: 62 }));
    } else if (questionIndex === 6) {
      dispatch(loading({ percent: 64 }));
    } else if (questionIndex === 7) {
      dispatch(loading({ percent: 66 }));
    } else if (questionIndex === 8) {
      dispatch(loading({ percent: 68 }));
    } else if (questionIndex === 9) {
      dispatch(loading({ percent: 70 }));
    } else if (questionIndex === 10) {
      dispatch(loading({ percent: 72 }));
    } else if (questionIndex === 11) {
      dispatch(loading({ percent: 74 }));
    } else if (questionIndex === 12) {
      dispatch(loading({ percent: 76 }));
    } else if (questionIndex === 13) {
      dispatch(loading({ percent: 78 }));
    } else if (questionIndex === 14) {
      dispatch(loading({ percent: 80 }));
    } else if (questionIndex === 15) {
      dispatch(loading({ percent: 82 }));
    } else if (questionIndex === 16) {
      dispatch(loading({ percent: 82 }));
    } else if (questionIndex === 17) {
      dispatch(loading({ percent: 82 }));
    } else if (questionIndex === 18) {
      dispatch(loading({ percent: 84 }));
    } else if (questionIndex === 19) {
      dispatch(loading({ percent: 86 }));
    } else if (questionIndex === 20) {
      dispatch(loading({ percent: 88 }));
    } else if (questionIndex === 21) {
      dispatch(loading({ percent: 90 }));
    } else if (questionIndex === 22) {
      dispatch(loading({ percent: 92 }));
    } else if (questionIndex === 23) {
      dispatch(loading({ percent: 94 }));
    } else if (questionIndex === 24) {
      dispatch(loading({ percent: 96 }));
    } else if (questionIndex === 25) {
      dispatch(loading({ percent: 98 }));
    }
  };

  const changeQuestion = (direction) => {
    if (direction === "next") {
      if (questionIndex === 15 && selected.name === "No") {
        setQuestionIndex(questionIndex + 3);
      } else if (questionIndex === 18 && selected.name === "No") {
        setQuestionIndex(questionIndex + 2);
      } else if (questionIndex === 20 && selected.name === "No") {
        setQuestionIndex(questionIndex + 2);
      } else if (questionIndex === 22 && selected.name === "No") {
        setQuestionIndex(questionIndex + 2);
      } else if (questionIndex === 24 && selected.name === "No") {
        saveTriage();
      } else {
        setQuestionIndex(questionIndex + 1);
      }
    } else if (direction === "previous") {
      let myData = EarProblemState.find(
        (e) => e.question === EAR_PROBLEM_QUESTIONS[questionIndex - 1].name
      );
      if (questionIndex === 18) {
        if (myData) {
          setQuestionIndex(questionIndex - 1);
        } else {
          setQuestionIndex(questionIndex - 3);
        }
      } else if (questionIndex === 20) {
        if (myData) {
          setQuestionIndex(questionIndex - 1);
        } else {
          setQuestionIndex(questionIndex - 2);
        }
      } else if (questionIndex === 22) {
        if (myData) {
          setQuestionIndex(questionIndex - 1);
        } else {
          setQuestionIndex(questionIndex - 2);
        }
      } else if (questionIndex === 24) {
        if (myData) {
          setQuestionIndex(questionIndex - 1);
        } else {
          setQuestionIndex(questionIndex - 2);
        }
      } else if (questionIndex === 26) {
        if (myData) {
          setQuestionIndex(questionIndex - 1);
        } else {
          setQuestionIndex(questionIndex - 2);
        }
      } else {
        setQuestionIndex(questionIndex - 1);
      }
    }
  };

  const saveTriage = async () => {
    let _index = EarProblemState.findIndex(
      (e) => e.question === currentQuestion.name
    );
    let myData = [...EarProblemState];
    if (_index > -1) {
      myData[_index] = {
        question: currentQuestion.name,
        answer: currentQuestion.type === "options" ? selected.name : text,
        code: currentQuestion.type === "options" ? selected.p : "P0",
      };
    } else {
      myData.push({
        question: currentQuestion.name,
        answer: currentQuestion.type === "options" ? selected.name : text,
        code: currentQuestion.type === "options" ? selected.p : "P0",
      });
    }

    let _time = (new Date().getTime() - new Date(MyTimer).getTime()).toString();
    let _data = [...myData].map((e) => {
      if (typeof e.code === "number") {
        return {
          question: e.question,
          code: `P${e.code}`,
          answer: e.answer,
        };
      } else {
        return {
          question: e.question,
          code: e.code,
          answer: e.answer,
        };
      }
    });

    const response = await postEarProblem(_data, _time);
    if (response?.status !== "Fail") {
      dispatch(earProblem(null));
      dispatch(timer(null));
      navigate("/post-triage");
    } else {
      toastify(response?.message, "error");
    }
  };

  const handleNext = async () => {
    if (questionIndex < 25) {
      if (
        (currentQuestion.type === "options" && selected.name !== undefined) ||
        (currentQuestion.type === "text" && text !== "")
      ) {
        saveHistory();
        changeQuestion("next");
      } else {
        if (currentQuestion.type === "options")
          toastify("Please select an option below", "error");
        else toastify("Please submit your answer", "error");
      }
    } else {
      if (
        (currentQuestion.type === "options" && selected.name !== undefined) ||
        (currentQuestion.type === "text" && text !== "")
      ) {
        saveHistory();

        saveTriage();
      } else {
        toastify("Please submit your answer", "error");
      }
    }
  };

  const handlePrevious = () => {
    if (questionIndex > 0) {
      changeQuestion("previous");
    } else {
      navigate("/triage");
    }
  };

  const handleInfo = (ques) => {
    if (ques === "Have you vomited with this ear problem?") {
      setPopupMessage("Have you been sick with this ear problem?");
    }
    setPopup(true);
  };

  const handleInfoQues = () => {
    if (currentQuestion?.name === "Have you vomited with this ear problem?") {
      return true;
    }
  };

  return (
    <div className="ecds-container">
      <Popup show={popup} setShow={setPopup} message={popupMessage} />
      {/* {questionIndex !== 0 && ( */}
      <div className="arrow-left">
        <img
          className="arrow-left-img"
          src={require("../../Assets/Images/arrow-left.png")}
          alt="arrow"
          onClick={handlePrevious}
        />
      </div>
      {/* )} */}

      <div className={`content-div`}>
        <img
          src={require("../../Assets/Images/logo.png")}
          className="logo-smaller"
          alt="logo"
        />

        <div className="question">
          {currentQuestion?.name}
          {handleInfoQues() && (
            <img
              src={require("../../Assets/Images/info-icon.png")}
              style={{ width: 18 }}
              className="cursor-pointer"
              onClick={() => handleInfo(currentQuestion?.name)}
            />
          )}
        </div>
        {getAnswerLayout()}
      </div>
      <button className="button-ecds-next br py-2" onClick={handleNext}>
        <span>Next</span>
      </button>
    </div>
  );
}
