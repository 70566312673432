import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { relationWith } from "../../redux/slices/basicDetialSlice";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';

export default function PersonRelationshipComponent() {
  const [relation, setRelation] = useState("");
  const [otherRelation, setOtherRelation] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleNext = () => {
    if (!relation) {
      return toast.error(`${t('select_option')}`);
    }
    if (relation === `${t('other')}` && !otherRelation) {
      return toast.error(`${t('submit_answer')}`);
    }
    dispatch(
      relationWith({
        relationshipToPerson: relation !== `${t('other')}` ? relation : otherRelation,
      })
    );
    navigate("/person-detail");
  };

  const handlePrevious = () => {
    navigate("/basic-details");
  };

  return (
    <div className="ecds-container">
      <div className="content-div">
        <div className="arrow-left">
          <img
            className="arrow-left-img"
            src={require("../../Assets/Images/arrow-left.png")}
            alt="arrow"
            onClick={handlePrevious}
          />
        </div>
        <img
          src={require("../../Assets/Images/logo.png")}
          className="logo-smaller"
          alt="logo"
        />
        <div className="question">
          {t('what_is_relation_person')}
        </div>
        <div className="h-custom">
          <div
            onClick={() => {
              setRelation(`${t('mother')}`);
            }}
            className={
              relation === `${t('mother')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('mother')}
          </div>
          <div
            onClick={() => {
              setRelation(`${t('father')}`);
            }}
            className={
              relation === `${t('father')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('father')}
          </div>
          <div
            onClick={() => {
              setRelation(`${t('husband')}`);
            }}
            className={
              relation === `${t('husband')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('husband')}
          </div>
          <div
            onClick={() => {
              setRelation(`${t('wife')}`);
            }}
            className={
              relation === `${t('wife')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('wife')}
          </div>
          <div
            onClick={() => {
              setRelation(`${t('partner')}`);
            }}
            className={
              relation === `${t('partner')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('partner')}
          </div>
          <div
            onClick={() => {
              setRelation(`${t('brother')}`);
            }}
            className={
              relation === `${t('brother')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('brother')}
          </div>
          <div
            onClick={() => {
              setRelation(`${t('sister')}`);
            }}
            className={
              relation === `${t('sister')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('sister')}
          </div>
          <div
            onClick={() => {
              setRelation(`${t('grandparent')}`);
            }}
            className={
              relation === `${t('grandparent')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('grandparent')}
          </div>
          <div
            onClick={() => {
              setRelation(`${t('son')}`);
            }}
            className={
              relation === `${t('son')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('son')}
          </div>
          <div
            onClick={() => {
              setRelation(`${t('daughter')}`);
            }}
            className={
              relation === `${t('daughter')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('daughter')}
          </div>
          <div
            onClick={() => {
              setRelation(`${t('carer')}`);
            }}
            className={
              relation === `${t('carer')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('carer')}
          </div>
          <div
            onClick={() => {
              setRelation(`${t('friend')}`);
            }}
            className={
              relation === `${t('friend')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('friend')}
          </div>
          <div
            onClick={() => {
              setRelation(`${t('neighbour')}`);
            }}
            className={
              relation === `${t('neighbour')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('neighbour')}
          </div>
          <div
            onClick={() => {
              setRelation(`${t('other')}`);
            }}
            className={
              relation === `${t('other')}`
                ? "active-option answer-div"
                : "inactive-option answer-div"
            }
          >
            {t('other')}
          </div>
          {relation === `${t('other')}` && (
            <>
              <div className="question">{t('please_tell_us_who_person')}</div>
              <div className="">
                <Form.Control
                  as="textarea"
                  className="trans-background no-resize color-white font-14 custom-field h-100p"
                  value={otherRelation}
                  placeholder={t('enter_answer')}
                  onChange={(e) => {
                    if (e.target.value.length < 31) {
                      setOtherRelation(e.target.value);
                    }
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <button className="button-ecds-next br py-2" onClick={handleNext}>
        <span>{t('next')}</span>
      </button>
    </div>
  );
}
