import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URI,
});

export const getEcdsData = async (version, language) => {
  try {
    let response = await api.post(
      "ecds/get_ecds_data",
      { version, language },
      {
        headers: {
          Authorization: localStorage.getItem("opto_token"),
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    console.log("ERROR", error);
    return error?.response?.data;
  }
};

export const getEcdsQuestionsList = async (version, language) => {

  try {
    if (version && language) {
      let response = await api.post(
        "ecdsQuestions/getEcdsQuestionList",
        { version, language },
        {
          headers: {
            Authorization: localStorage.getItem("opto_token"),
          },
        }
      );
      return response?.data?.data;
    }
  } catch (error) {
    console.log("ERROR", error);
    return error?.response?.data;
  }
};

export const postEcds = async (data) => {
  try {
    let response = await api.post("ecds/update_user_ecds_data", data, {
      headers: {
        Authorization: localStorage.getItem("opto_token"),
      },
    });
    return response?.data?.data;
  } catch (error) {
    console.log("ERROR", error);
    return error?.response?.data;
  }
};
