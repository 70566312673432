import React, { useEffect, useState } from "react";
import { FormControl, Input, InputAdornment } from "@mui/material";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";
import { postAbdominalPain } from "../../APIs/earProblem";
import { earProblem } from "../../redux/slices/earProblemSlice";
import { timer } from "../../redux/slices/timerSlice";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { loading } from "../../redux/slices/loadingSlice";
import loader from "../../Assets/Images/loader.gif";
import Popup from "../AbdominalPain/Popup";
import { useTranslation } from 'react-i18next';
import { screenTimer } from "../../redux/slices/screenTimerSlice";
import Dialog from "@mui/material/Dialog";

export default function ClinicalPathwayComponent() {
  const [answer1, setAnswer1] = useState();
  const [currentQues, setCurrentQues] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const problemType = useSelector((state) => state.problemType.value.problem);
  const questions = useSelector((state) => state.pathwayQuestions.value);
  const userData = useSelector((state) => state.userData.value);
  const isLightMode = useSelector(state => state.isLightMode?.value)
  const [quesList, setQuesList] = useState([]);
  const [multiOptions, setMultiOptions] = useState([]);
  const [selectedQues, setSelectedQues] = useState([]);
  const [currentSelectedOption, setCurrentSelectedOption] = useState(null);
  const [multiSelected, setMultiSelected] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [popup, setPopup] = useState(false);
  const [search, setSearch] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [filteredData, setFilteredData] = useState([]);
  const usingLanguage = localStorage.getItem('i18nextLng')
  const { state } = useLocation()

  const { t } = useTranslation();
  var MyTimer = useSelector((state) => state.timer.value);
  useEffect(() => {
    if (questions?.length) {
      setCurrentQues(questions.find((q) => q.isParentQuestion === true));
    }
  }, [questions]);

  useEffect(() => {
    dispatch(loading({ percent: 50 }));
    dispatch(screenTimer(40))
    if (questions.length === 0) {
      toast.error(`${t('no_question_found_msg')}`);
    }
  }, []);

  useEffect(() => {
    if (search === "") setFilteredData([]);
    else {
      filtering();
    }
  }, [search]);

  const filtering = () => {
    let _answers = currentQues.options?.filter((e) =>
      e.option?.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredData(_answers);
    if (_answers.length === 0) {
      toast.error(`${t('no_results_found')}`);
    }
  };

  const formatDob = (date) => {
    if (date) {
      let y = date.substring(0, 4);
      let m = date.substring(4, 6);
      let d = date.substring(6, 8);
      const age = `${y}-${m}-${d}`;
      return moment().diff(age, "years");
    }
  };

  const selectQuestion = (question_number) => {
    const ques = questions.find((q) => q.questionNumber === question_number);
    if (ques) {
      return ques;
    } else {
      setShowErrorAlert(true)
    }
  };

  const handleAnsAndProgress = () => {
    setQuesList(quesList.concat(currentQues));
    handleProgressBar();
  }

  const handleNext = async () => {
    const gender =
      userData?.user.gender.charAt(0).toUpperCase() +
      userData?.user.gender.slice(1);
    const age = formatDob(userData?.user.dob);
    const { question, multiLanguageQuestion } = currentQues;
    const multiLangQues = multiLanguageQuestion?.length && multiLanguageQuestion.find((d) => (d.language === 'en')).content || multiLanguageQuestion[0]?.content;
    let _time = (new Date().getTime() - new Date(MyTimer).getTime()).toString();
    dispatch(screenTimer(40))
    if (currentQues?.questionType === "input" && inputValue === "") {
      return toast.error(`${t('submit_answer')}`);
    }
    if (
      !multiOptions.length &&
      !currentSelectedOption &&
      currentQues?.questionType === "singleOption"
    ) {
      return toast.error(`${t('select_option')}`);
    }
    if (
      !multiOptions.length &&
      !currentSelectedOption &&
      currentQues?.questionType === "multiOption"
    ) {
      return toast.error(`${t('select_option')}`);
    }
    if (multiOptions.length) {
      let code = [];
      let answer = [];
      selectedQues.filter((s) => s.question !== multiLangQues || question);
      for (const e of multiOptions) {
        code.push(e.code);
        answer.push(e.option);
      }
      const obj = {
        code: code.toString(),
        answer: answer.toString(),
        question: multiLangQues || question,
				questionId: currentQues._id,
				selectedOptionId: currentSelectedOption._id,
      };
      const questionIndex = selectedQues.findIndex(
        (q) => q.question === multiLangQues || question
      );
      questionIndex !== -1
        ? (selectedQues[questionIndex] = obj)
        : selectedQues.push(obj);
      setMultiOptions([]);
      setMultiSelected(!multiSelected);
      setCurrentQues(selectQuestion(multiOptions[0]?.nextQuestionNumber));
      setQuesList(quesList.concat(currentQues));
      handleProgressBar();
    }
    if (currentSelectedOption) {
      // setAgeFilter(false);
      const { code, option, condition, conditionalCode, multiLanguageOption } = currentSelectedOption;
      const multiLangOptions = multiLanguageOption?.length && multiLanguageOption.find((d) => (d.language === 'en')).content || multiLanguageOption[0]?.content || option;
      let getConditionCodeValue = false
      let obj;
      if (
        condition?.applyAge && !condition.conditionalGenders.includes(gender.toString()) && !condition.skipQuestion &&
        age > condition.ageRange[0] && age < condition.ageRange[1]
      ) {
        getConditionCodeValue = true;
      }
      // if (condition?.applyGender) {
      //   setGenderFilter(true);
      // }
      if (getConditionCodeValue) {
        obj = {
          code: conditionalCode,
          answer: multiLangOptions || option,
          question: multiLangQues || question,
					questionId: currentQues._id,
					selectedOptionId: currentSelectedOption._id,
        };
        getConditionCodeValue = false;
      } else {
        obj = {
          code: code ? code : "P0",
          answer: multiLangOptions || option,
          question: multiLangQues || question,
					questionId: currentQues._id,
					selectedOptionId: currentSelectedOption._id,
        };
      }
      const questionIndex = selectedQues.findIndex(
        (q) => q.question === multiLangQues
      );
      questionIndex !== -1
        ? (selectedQues[questionIndex] = obj)
        : selectedQues.push(obj);
      setCurrentSelectedOption(null);
      setAnswer1("");
    }
    if (inputValue) {
      const inputGiven = inputValue
      const obj = {
        code: "P0",
        answer: inputGiven,
        question: multiLangQues || question,
      };
      const questionIndex = selectedQues.findIndex(
        (q) => q.question === multiLangQues
      );
      questionIndex !== -1
        ? (selectedQues[questionIndex] = obj)
        : selectedQues.push(obj);
      setInputValue("");
      setCurrentQues(selectQuestion(currentQues.nextQuestionNumber));
      setQuesList(quesList.concat(currentQues));
      handleProgressBar();
      // setNextInput(!nextInput);
    }
    if (
      (currentQues.questionType === "input" &&
        currentQues?.nextQuestionNumber === -1) ||
      currentSelectedOption?.nextQuestionNumber === -1
    ) {
      const payload = {
				disease: localStorage.getItem("diseaseId"),
        record: selectedQues,
        timer: _time,
        problem_type: problemType,
        hospital: localStorage.getItem("hospitalId"),
        language: usingLanguage
      };
			console.log("payload = ", payload);
      setShowLoader(true);
      handleProgressBar();
      const response = await postAbdominalPain(payload);
      if (response) {
        setShowLoader(false);
        dispatch(earProblem(null));
        dispatch(timer(null));
        navigate("/post-triage");
      } else {
        toast.error(response?.message);
        setShowLoader(false);
      }
    }

    const { applyAge, applyGender, skipQuestion, ageRange } = currentSelectedOption?.condition;

    const ageGenderCondition = () => {
      if (skipQuestion) {
        setCurrentQues(selectQuestion(currentSelectedOption.condition.conditionalQuestion))
        handleAnsAndProgress()
      } else {
        setCurrentQues(selectQuestion(currentSelectedOption?.nextQuestionNumber))
        handleAnsAndProgress()
      }
    }

    const ageRangecondition = () => {
      if (skipQuestion) {
        if (age > ageRange[0] && age < ageRange[1]) {
          setCurrentQues(selectQuestion(currentSelectedOption?.nextQuestionNumber))
          handleAnsAndProgress()
        } else {
          setCurrentQues(selectQuestion(currentSelectedOption.condition.conditionalQuestion))
          handleAnsAndProgress()
        }
      } else {
        setCurrentQues(selectQuestion(currentSelectedOption?.nextQuestionNumber))
        handleAnsAndProgress()
      }
    }

    if (applyAge && applyGender) {
      if (currentSelectedOption.condition.conditionalGenders.includes(gender.toString())) {
        ageGenderCondition()
      } else {
        ageRangecondition()
      }
    } else if (applyGender && !applyAge) {
      if (currentSelectedOption.condition.conditionalGenders.includes(gender.toString())) {
        ageGenderCondition()
      } else {
        setCurrentQues(selectQuestion(currentSelectedOption?.nextQuestionNumber))
        handleAnsAndProgress()
      }
    } else if (!applyGender && applyAge) {
      ageRangecondition()
    } else {
      setCurrentQues(selectQuestion(currentSelectedOption?.nextQuestionNumber))
      handleAnsAndProgress()
    }
    // if (!currentSelectedOption?.condition.applyGender) {
    //   currentSelectedOption &&
    //     setCurrentQues(
    //       selectQuestion(currentSelectedOption?.nextQuestionNumber)
    //     );
    //   // : setCurrentQues(selectQuestion(currentQues.nextQuestionNumber));
    //   setQuesList(quesList.concat(currentQues));
    //   handleProgressBar();
    // } else if (currentSelectedOption?.condition.applyGender) {
    //   currentSelectedOption.condition.conditionalGenders.includes(
    //     gender.toString()
    //   ) && currentSelectedOption.condition.skipQuestion
    //     ? setCurrentQues(
    //       selectQuestion(currentSelectedOption.condition.conditionalQuestion)
    //     )
    //     : setCurrentQues(
    //       selectQuestion(currentSelectedOption?.nextQuestionNumber)
    //     );
    //   setQuesList(quesList.concat(currentQues));
    //   handleProgressBar();
    // }
  };

  const handlePrevious = () => {
    if (questions.length === 0) {
      navigate("/triage");
    }
    dispatch(screenTimer(40))
    if (currentQues.isParentQuestion === true) {
      navigate("/triage");
    } else {
      setInputValue("");
      let a = quesList.pop();
      setCurrentQues(selectQuestion(a?.questionNumber));
      const pre = quesList[quesList.length - 1];
      const current = selectQuestion(a.questionNumber);
      if (currentQues?.questionNumber > 2) {
        // const loadingValue1 = ((pre.questionNumber / questions?.length) * 100) / 2;
        // const updatedValue1 = Math.round(loadingValue1 + 50);
        // dispatch(loading({ percent: updatedValue1 }));
        // const currentQuestionIndex = quesList.length - 1;
        const currentQuestionIndex = questions.indexOf(pre);
        const loadingValue = ((currentQuestionIndex / questions?.length) * 100) / 2;
        const updatedValue = Math.round(loadingValue + 51);
        dispatch(loading({ percent: updatedValue }));
      }
      for (let s of selectedQues) {
        for (let c of current?.options) {
          if (current.question === s.question && c.option === s.answer) {
            setCurrentSelectedOption(c);
            setAnswer1(c.option);
          }
        }
      }
    }
  };

  const handleMultiOptions = (o) => {
    setAnswer1(getSelectedOptionName(o));
    if (!multiOptions.includes(o)) {
      setMultiOptions(multiOptions.concat(o));
    } else {
      const filtered = multiOptions.filter((m) => m.option !== getSelectedOptionName(o));
      setMultiOptions(filtered);
    }
  };

  const handleProgressBar = () => {
    const currentQuestionIndex = questions.indexOf(currentQues);
    const loadingValue = ((currentQuestionIndex / questions?.length) * 100) / 2;
    const updatedValue = Math.round(loadingValue + 51);
    (currentQues.questionType === "input" &&
      currentQues?.nextQuestionNumber === -1) ||
      (currentQues.questionType === "singleOption" &&
        currentSelectedOption?.nextQuestionNumber === -1)
      ? dispatch(loading({ percent: 100 }))
      : dispatch(loading({ percent: updatedValue }));
  };

  var getSelectedOptionName = (o) => {
    return o?.multiLanguageOption?.length ? o?.multiLanguageOption.find((d) => (d.language === usingLanguage))?.content || o?.multiLanguageOption[0]?.content : o.option
  }

  const getCurrentQuesContent = () => {
    return currentQues?.multiLanguageQuestion?.length ? currentQues.multiLanguageQuestion?.find((d) => (d.language === usingLanguage))?.content || currentQues.multiLanguageQuestion[0]?.content : currentQues?.question
  }

  return (
    <div className="ecds-container">
      <div className="content-div">
        <Popup show={popup} setShow={setPopup} message={currentQues?.multiLanguageInfo?.length ? currentQues.multiLanguageInfo.find((d) => (d.language === usingLanguage))?.content || currentQues.multiLanguageInfo[0]?.content : currentQues?.info} />
        {/* <Dialog open={showErrorAlert} className={`popup-parent ${isLightMode && "dark-color"}`} onClick={() => navigate("/")}>
          <div className="child-popup">
            <div className="popup-heading">{t('error_encountered')}</div>
            <div className="popup-content">{t('error_encountered_message')}</div>
          </div>
        </Dialog> */}

        <div className="arrow-left">
          <img
            className="arrow-left-img"
            src={require("../../Assets/Images/arrow-left.png")}
            alt="arrow"
            onClick={handlePrevious}
          />
        </div>
        <img
          src={require("../../Assets/Images/logo.png")}
          className="logo-smaller"
          alt="logo"
        />
        {showLoader && (
          <img className="loader_div" src={loader} alt="loading..." />
        )}
        <div className="question">
          {currentQues?.multiLanguageQuestion?.length ? currentQues.multiLanguageQuestion?.find((d) => (d.language === usingLanguage))?.content || currentQues.multiLanguageQuestion[0]?.content : currentQues?.question}
          {currentQues?.isInfoButtonEnable && (
            <img
              src={require("../../Assets/Images/info-icon.png")}
              style={{ width: 18 }}
              className="cursor-pointer"
              onClick={() => setPopup(true)}
            />
          )}
        </div>
        {currentQues && currentQues.enableSearch && (
          <FormControl className="answer-multiple">
            <Input
              disableUnderline
              onChange={(e) => setSearch(e.target.value)}
              style={{ fontSize: 12, marginTop: 3 }}
              sx={{ input: { color: "white" } }}
              value={search}
              placeholder={t('search')}
              startAdornment={
                <InputAdornment position="start">
                  <img
                    src={require("../../Assets/Images/searchIcon.png")}
                    className="logo-smallest"
                  />
                </InputAdornment>
              }
            />
          </FormControl>
        )}
        {currentQues?.questionType === "input" && (
          <>
            <div className="">
              <Form.Control
                value={inputValue}
                onChange={(e) => {
                  if (e.target.value.length < 100) {
                    setInputValue(e.target.value);
                  }
                }}
                rows={3}
                as="textarea"
                placeholder={t('enter_answer')}
                className="trans-background no-resize color-white font-14 custom-field "
              />
            </div>
          </>
        )}

        {currentQues?.questionType === "singleOption" && (
          <>
            {state.showBadPainImages && (getCurrentQuesContent()?.includes("How bad is") || getCurrentQuesContent()?.includes("ما مدى")) ?
              <div>
                {/* {currentQues.options?.map((o, i) => {
              return ( */}
                <div
                  onClick={() => {
                    setAnswer1(getSelectedOptionName(currentQues.options[0]));
                    setCurrentSelectedOption(currentQues.options[0]);
                  }}
                  className={
                    answer1 === getSelectedOptionName(currentQues.options[0])
                      ? "active-option answer-div pain-div"
                      : "inactive-option answer-div pain-div normal-pain-image"
                  }

                >
                  {isLightMode ? <img src={require("../../Assets/Images/normal-pain-dark1.png")} alt="pain-image" /> : <img src={require("../../Assets/Images/normal-pain-light-img1.png")} alt="pain-image" />}

                  {getSelectedOptionName(currentQues.options[0])}
                </div>
                <div
                  onClick={() => {
                    setAnswer1(getSelectedOptionName(currentQues.options[1]));
                    setCurrentSelectedOption(currentQues.options[1]);
                  }}
                  className={
                    answer1 === getSelectedOptionName(currentQues.options[1])
                      ? "active-option answer-div pain-div"
                      : "inactive-option answer-div pain-div normal-pain-image"
                  }
                >
                  {isLightMode ? <img src={require("../../Assets/Images/normal-pain-dark2.png")} alt="pain-image" /> : <img src={require("../../Assets/Images/normal-pain-light-img2.png")} alt="pain-image" />}
                  {getSelectedOptionName(currentQues.options[1])}
                </div>
                <div
                  onClick={() => {
                    setAnswer1(getSelectedOptionName(currentQues.options[2]));
                    setCurrentSelectedOption(currentQues.options[2]);
                  }}
                  className={
                    answer1 === getSelectedOptionName(currentQues.options[2])
                      ? "active-option answer-div pain-div"
                      : "inactive-option answer-div pain-div normal-pain-image"
                  }
                >
                  {isLightMode ? <img src={require("../../Assets/Images/normal-pain-dark3.png")} alt="pain-image" /> : <img src={require("../../Assets/Images/normal-pain-light-img3.png")} alt="pain-image" />}
                  {getSelectedOptionName(currentQues.options[2])}
                </div>
                {/* );
            })} */}
              </div>
              :
              <div>
                {(filteredData.length === 0 && search
                  ? []
                  : filteredData.length === 0
                    ? currentQues.options
                    : filteredData
                )?.map((o) => {
                  return (
                    <div
                      onClick={() => {
                        setAnswer1(getSelectedOptionName(o));
                        setCurrentSelectedOption(o);
                      }}
                      className={
                        answer1 === getSelectedOptionName(o)
                          ? "active-option answer-div"
                          : "inactive-option answer-div"
                      }
                    >
                      {getSelectedOptionName(o)}
                    </div>
                  );
                })}
              </div>
            }

          </>
        )}

        {currentQues?.questionType === "multiOption" && (
          <div>
            {currentQues?.options.map((o) => {
              return (
                <div
                  onClick={() => handleMultiOptions(o)}
                  className={
                    multiOptions.includes(o)
                      ? "active-option answer-div"
                      : "inactive-option answer-div"
                  }
                >
                  {o.option}
                </div>
              );
            })}
          </div>
        )}
      </div>

      <button className="button-ecds-next br py-2" onClick={handleNext}>
        <span>{t('Next')}</span>
      </button>
    </div>
  );
}
