import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import AbdominalPainComponent from "../../Components/AbdominalPain/AbdominalPaincomponent";
import { ABDOMINAL_PAIN_QUESTIONS } from "../../Config/constants";
import { questions } from "../../redux/slices/questionsSlice";
import ProgressSlider from "../../Components/Shared/ProgressSlider";

export default function AbdominalPain() {
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({
    query: "(max-width: 820px)",
  });

  useEffect(() => {
    dispatch(questions(ABDOMINAL_PAIN_QUESTIONS));
  }, []);

  return (
    <div className="custom-container">
      {isMobile ? (
        <div className="mobile-box-pe">
          <div className="slider">
            <ProgressSlider />
          </div>
          <AbdominalPainComponent />
        </div>
      ) : (
        <div className="backdrop">
          <div className="pc-box-pe position-relative">
            <div className="slider">
              <ProgressSlider />
            </div>
            <AbdominalPainComponent />
          </div>
        </div>
      )}
    </div>
  );
}
