import React from "react";
import { useMediaQuery } from "react-responsive";
import EmergencyContactComponent from "../../Components/EmergencyContact/EmergencyContactComponent";

export default function EmergencyContact() {
  const isMobile = useMediaQuery({
    query: "(max-width: 820px)",
  });

  return (
    <div className="custom-container">
      {isMobile ? (
        <div className="mobile-box">
          <EmergencyContactComponent />
        </div>
      ) : (
        <div className="backdrop">
          <div className="pc-box position-relative">
            <EmergencyContactComponent />
          </div>
        </div>
      )}
    </div>
  );
}
