import React from "react";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

export default function Popup({ show, setShow }) {
  const { t } = useTranslation();
  const isLightMode = useSelector(state => state.isLightMode?.value)

  return (
    <Dialog open={show} className={`popup-parent ${isLightMode && "dark-color"}`}>
      <div className="child-popup">
        <div className="popup-heading">{t('Immunosuppressed')}</div>
        <div className="popup-content">
          {t('immo_info')}
        </div>
        <button className="button-popup py-1" onClick={() => setShow(false)}>
          {t('ok')}
        </button>
      </div>
    </Dialog>
  );
}
