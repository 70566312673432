import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { ecds } from "../../redux/slices/ecdsSlice";
import { useNavigate } from "react-router-dom";
import { loading } from "../../redux/slices/loadingSlice";
import { useTranslation } from 'react-i18next';
import Popup from "./Popup";

const PreEcdsQuestionsComponent = () => {
  const { questions } = useSelector(state => state.ecds)
  const { version } = useSelector(state => state.basicDetail)
  const [preQuestions, setPreQuestion] = useState(questions?.slice(0, version === 'paediatric' ? 6 : 7));
  const [userResponses, setUserResponses] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [popup, setPopup] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleResponseChange = (ques, value) => {
    const { index, name } = ques;
    setUserResponses({
      ...userResponses,
      [index]: { value, name },
    });
  };
  const validateResponses = () => {
    const areAllQuestionsAnswered = preQuestions.every((q) => userResponses[q.index] !== undefined);
    setIsValid(areAllQuestionsAnswered);
  };

  const handleNext = () => {
    if (isValid) {
      const data = []
      const entries = Object.entries(userResponses);
      for (const [key, value] of entries) {
        data.push({
          question: +key,
          type: "Bool",
          answer: value.value,
          ques: value.name
        })
      }
      dispatch(ecds(data))
      navigate("/ecds")
    } else {
      toast.error('please select all the answers below');
    }
  };

  const handlePrevious = () => {
    navigate("/");
  }

  useEffect(() => {
    dispatch(loading({ percent: 0 }));
  }, [])

  useEffect(() => {
    validateResponses();
  }, [userResponses]);

  return (
    <div className="ecds-container">
      <Popup show={popup} setShow={setPopup} />
      <div className="arrow-left">
        <img
          className="arrow-left-img"
          src={require("../../Assets/Images/arrow-left.png")}
          alt="arrow"
          onClick={handlePrevious}
        />
      </div>
      <div className="d-flex check_question_wrap">
        <img
          src={require("../../Assets/Images/logo.png")}
          className="logo-smaller"
          alt="logo"
        />
        <h2>{t('please_select_those_that_apply')}</h2>
        {preQuestions.map((q) => (
          <div className="check_question d-flex" key={q.index}>
            <span>
              {q.name}
              {q.name === t(version === "firstPerson" ? "is_ammo_" : "is_ammo") && (
                <span className="ms-1 info-icon-color cursor-pointer" onClick={() => setPopup(true)}>ⓘ</span>
              )}
            </span>
            <label htmlFor={`yes-${q.index}`} className="check_box">
              <input
                type="radio"
                value="Yes"
                id={`yes-${q.index}`}
                onChange={() => handleResponseChange(q, true)}
                checked={userResponses[q.index]?.value === true}
              />
              <span>{t('yes')}</span>
            </label>
            <label htmlFor={`no-${q.index}`} className="check_box">
              <input
                type="radio"
                value="No"
                id={`no-${q.index}`}
                onChange={() => handleResponseChange(q, false)}
                checked={userResponses[q.index]?.value === false}
              />
              <span>{t('no')}</span>
            </label>
          </div>
        ))}
      </div>
      <button className="button-ecds-next br py-2" onClick={() => handleNext()}>
        <span>{t('next')}</span>
      </button>
    </div>
  );
}
export default PreEcdsQuestionsComponent;