import { toast } from "react-toastify";

export const toastify = (message, status, heading) => {
  if (status === "success") {
    toast.success(
      <div>
        <span className="bold">{heading || "Success"}</span> <br /> {message}
      </div>
    );
  } else {
    toast.error(
      <div>
        <span className="bold">{heading || "Error"}</span> <br /> {message}
      </div>
    );
  }
};
