import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { createDevice } from "../../APIs/hospital";
import { useTranslation } from 'react-i18next';
import loader from "../../Assets/Images/loader.gif";

export default function MainComponent() {
  const [otp, setOtp] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleChange = (otp) => {
    setOtp(otp);
  };

  const handleNext = async () => {
    setShowLoader(true)
    // const ip = await getIp();
    const ip = '192.168.1.12'
    const approved = await createDevice({
      deviceType: "web",
      deviceName: ip,
      code: otp,
    });
    if (approved.status) {
      localStorage.setItem("deviceId", approved?.data._id);
      localStorage.setItem("hospitalId", approved?.data.hospital);
      setShowLoader(false);
      navigate("/");
    } else {
      return toast.error(`${t('please_enter_complete_code')}`);
    }
  };

  var getIp = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    return res.data.IPv4;
  };

  useEffect(() => {
    const deviceId = localStorage.getItem("deviceId");
    if (deviceId) {
      navigate("/booking");
    }
  }, []);

  return (
    <div className="ecds-container mainComp">
      <img
        src={require("../../Assets/Images/logo.png")}
        className="logo-smaller"
        alt="logo"
      />
      {showLoader && (
        <img className="loader_div" src={loader} alt="loading..." />
      )}
      <div className="question">{t('enter_hospital_id')}</div>
      <div className="d-flex justify-content-center output-wrap">
        <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={6}
          isInputNum={true}
          focusStyle={"focusStyle"}
          inputStyle={"hospitalId"}
        />
      </div>
      <button className="button-ecds-nexts br py-2">
        <span onClick={handleNext}>{t('next')}</span>
      </button>
    </div>
  );
}
