import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ecds, ecdsQuestions } from "../../redux/slices/ecdsSlice";
import { loading } from "../../redux/slices/loadingSlice";
import { useTranslation } from 'react-i18next';
import { getEcdsQuestionsList } from "../../APIs/ecds";

export default function PreEcdsComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [questionsList, setQuestionsList] = useState([])
  const { version } = useSelector(state => state.basicDetail)
  const usingLanguage = localStorage.getItem('i18nextLng')

  const handleNext = () => {
    if (questionsList) {
      navigate("/pre-ecds-questions");
    }
  };

  let questions = [
    { index: 1, type: "Bool", name: `${t('having_cough')}` },
    {
      index: 2,
      type: "Bool",
      name: `${t('having_temp')}`,
    },
    {
      index: 3,
      type: "Bool",
      name: `${t('having_covid')}`,
    },
    { index: 4, type: "Bool", name: `${t('is_chemo')}` },
    { index: 5, type: "Bool", name: `${t('radio')}` },
    {
      index: 6,
      type: "Bool",
      name: `${t('is_ammo_')}`,
    },
    {
      index: 7,
      type: "Bool",
      name: `${t('question from backend')}`,
    },
    {
      index: 8,
      type: "Ethnic_Category",
      name: `${t('select_ethnicity')}`,
    },
    {
      index: 9,
      type: "Spoken_Language",
      name: `${t('what_is_your_native')}`,
    },
    { index: 10, type: "Bool", name: `${t('required_interpreter')}` },
    {
      index: 11,
      type: "Interpreter_Language",
      name: `${t('which_interpreter_lang')}`,
    },
    {
      index: 12,
      type: "Accommodation_Status",
      name: `${t('accomodation_status_des')}`,
    },
    {
      index: 13,
      type: "Attendance_Activity",
      name: `${t('attandance_ac_1')}`,
    },
    { index: 14, type: "Arrival_Mode", name: `${t('how_did_you_travel')}` },
    {
      index: 15,
      type: "Bool",
      name: `${t('attandance_source_des')}`,
    },
    {
      index: 16,
      type: "Addendance_Sources",
      name: `${t('please_select_your_attendance_source')}`,
    },
    {
      index: 17,
      type: "Bool",
      name: `hello how are you`,
    },
    {
      index: 18,
      type: "Reason",
      name: `${t('why_are_you_here')}`,
    },
    { index: 19, type: "Date", name: `${t('injury_date_title')}` },
    { index: 20, type: "Time", name: `${t('injury_time_status')}` },
    {
      index: 21,
      type: "Place_Of_Injury",
      name: `${t('where_did_injury')}`,
    },
    {
      index: 22,
      type: "Injury_Intent",
      name: `${t('how_did_injury')}`,
    },
    {
      index: 23,
      type: "Injury_Acrtivity_Status",
      name: `${t('doing_while_injury')}`,
    },
    {
      index: 24,
      type: "Injury_Activity_Type",
      name: `${t('what_were_you')}`,
    },
    // {
    //   index: 23,
    //   type: "Attendance_Activity",
    //   name: "Have you attended another emergency department for the same reason in the last 7 days?",
    // },
    {
      index: 25,
      type: "Bool",
      name: `${t('select_hearing')}`,
    },
    {
      index: 26,
      type: "Support_Communicating",
      name: `${t('do_you_need_support')}`,
    },
  ];
  const getQuestionsList = async () => {
    const data = await getEcdsQuestionsList(version, usingLanguage)
    const updatedQuestions = []
    if (data.length) {
      for (let i = 0; i < data?.length; i++) {
        if (i < 8) {
          let question = questions[i];
          question.name = data[i].des;
          updatedQuestions.push(question)
        } else if (i === 8 || i === 9) {
          continue;
        } else {
          let question = questions[i - 2];
          const d = { ...question, name: data[i].des }
          updatedQuestions.push(d)
        }
      }
      const otherQuestions = [
        {
          index: 25,
          type: "Bool",
          name: data[8]?.des,
        },
        {
          index: 26,
          type: "Support_Communicating",
          name: data[9]?.des,
        },
      ]
      var allEcdsQuestion = [...updatedQuestions, ...otherQuestions]
      dispatch(ecdsQuestions(allEcdsQuestion))
      setQuestionsList(allEcdsQuestion)
    }
  }

  useEffect(() => {
    dispatch(loading({ percent: 0 }));
    dispatch(ecds(null));
    getQuestionsList()
  }, []);

  return (
    <div className="px-4 d-flex flex-column align-items-center">
      <img
        src={require("../../Assets/Images/smile.png")}
        className="smilie"
        alt="logo"
      />

      <div className="info2 my-5 px-4">
        {t(version === "firstPerson" ? 'next_few_questions' : 'next_few_questions1')}
      </div>

      <div className=" d-flex flex-column align-items-center">
        <button className="button-started py-2 br" onClick={handleNext}>
          <span>{t('next')}</span>
        </button>
      </div>
    </div>
  );
}
