import { useTranslation } from 'react-i18next';

export const GENDERS = () => {
  const { t } = useTranslation();
  return [
    { value: "male", name: `${t('male')}` },
    { name: `${t('female')}`, value: "female" },
    // { name: "Other", value: "other" },
  ]
};

export const PROBLEMS = [
  { _id: 1, diseaseName: "Abdominal Pain", reason: "General" },
  { _id: 1, diseaseName: "Anxiety", reason: "Illness" },
  { _id: 0, diseaseName: "Back Pain", reason: "General" },
  { _id: 0, diseaseName: "Behaviour Change", reason: "Illness" },
  { _id: 0, diseaseName: "Chest Pain", reason: "General" },
  { _id: 0, diseaseName: "Dental Problems", reason: "General" },
  // { _id: 7, name: "Ear Problems" },
  { _id: 1, diseaseName: "I have been assaulted", reason: "Injury" },
  { _id: 1, diseaseName: "Anal Pain", reason: "General" },
  { _id: 1, diseaseName: "Allergic Reaction", reason: "Illness" },
  { _id: 1, diseaseName: "Arm injury", reason: "Injury" },
  { _id: 1, diseaseName: "Hip Pain", reason: "General" },
  { _id: 1, diseaseName: "Hand Injury", reason: "Injury" },
];

export const EAR_PROBLEM_QUESTIONS = [
  {
    index: 1,
    name: "Please breifly describe your ear problem",
    type: "text",
    p: 0,
  },
  {
    index: 2,
    name: "Please tell us how long you have had this problem?",
    type: "options",
    options: [
      {
        name: "Less than 1 day",
        p: 5,
      },
      {
        name: "1-3 days",
        p: 2,
      },
      {
        name: "4-6 days",
        p: 5,
      },
      {
        name: "1-4 weeks",
        p: 5,
      },
      {
        name: "1-3 months",
        p: 5,
      },
      {
        name: "3-6 months",
        p: 5,
      },
      {
        name: "more than 6 months",
        p: 4,
      },
    ],
  },
  {
    index: 3,
    name: "Which ear is causing you concern?",
    type: "options",
    options: [
      {
        name: "Left",
        p: 5,
      },
      {
        name: "Right",
        p: 5,
      },
      {
        name: "Both",
        p: 5,
      },
    ],
  },
  {
    index: 4,
    name: "Is there an object or something else stuck in your ear?",
    type: "options",
    options: [
      {
        name: "Yes",
        p: 4,
      },
      {
        name: "No",
        p: 5,
      },
      {
        name: "I am not sure",
        p: 4,
      },
    ],
  },
  {
    index: 5,
    name: "Do you have a temperature (more than 38°C or 100°F)?",
    type: "options",
    options: [
      {
        name: "Yes",
        p: 4,
      },
      {
        name: "No",
        p: 5,
      },
      {
        name: "Don't know",
        p: 4,
      },
    ],
  },
  {
    index: 6,
    name: "Have you had any shivering with feeling cold?",
    type: "options",
    options: [
      {
        name: "Yes",
        p: 3,
      },
      {
        name: "No",
        p: 5,
      },
    ],
  },
  {
    index: 7,
    name: "Do you have a stiff neck?",
    type: "options",
    options: [
      {
        name: "Yes",
        p: 3,
      },
      {
        name: "No",
        p: 5,
      },
    ],
  },
  {
    index: 8,
    name: "Do you have a rash anywhere on your body?",
    type: "options",
    options: [
      {
        name: "Yes",
        p: 3,
      },
      {
        name: "No",
        p: 5,
      },
    ],
  },
  {
    index: 9,
    name: "Do you currently have a cold, sore throat or flu-like illness?",
    type: "options",
    options: [
      {
        name: "Yes",
        p: 4,
      },
      {
        name: "No",
        p: 5,
      },
    ],
  },
  {
    index: 10,
    name: "Is there any discharge coming from the ear?",
    type: "options",
    options: [
      {
        name: "Yes",
        p: 4,
      },
      {
        name: "No",
        p: 5,
      },
    ],
  },
  {
    index: 11,
    name: "Is the outer part of the ear very swollen, red and tender?",
    type: "options",
    options: [
      {
        name: "Yes",
        p: 4,
      },
      {
        name: "No",
        p: 5,
      },
    ],
  },
  {
    index: 12,
    name: "Do you have severe pain or swelling behind the ear?",
    type: "options",
    options: [
      {
        name: "Yes",
        p: 4,
      },
      {
        name: "No",
        p: 5,
      },
    ],
  },
  {
    index: 13,
    name: "Do you have any swelling in front of their ear or on the side of your face?",
    type: "options",
    options: [
      {
        name: "Yes",
        p: 3,
      },
      {
        name: "No",
        p: 5,
      },
    ],
  },
  {
    index: 14,
    name: "Since the ear problem started are you having any hearing loss?",
    type: "options",
    options: [
      {
        name: "Yes",
        p: 4,
      },
      {
        name: "No",
        p: 5,
      },
    ],
  },
  {
    index: 15,
    name: "Since the ear problem started are you having any ringing in your ears?",
    type: "options",
    options: [
      {
        name: "Yes",
        p: 4,
      },
      {
        name: "No",
        p: 5,
      },
    ],
  },
  {
    index: 16,
    name: "Have you vomited with this ear problem?",
    type: "options",
    options: [
      {
        name: "Yes",
        p: 3,
      },
      {
        name: "No",
        p: 5,
      },
    ],
  },
  {
    index: 17,
    name: "How many times have you been sick?",
    type: "text",
  },
  {
    index: 18,
    name: "Is the vomit brown?",
    type: "options",
    options: [
      {
        name: "Yes",
        p: 3,
      },
      {
        name: "No",
        p: 5,
      },
    ],
  },
  {
    index: 19,
    name: "Have you had ear problems before?",
    type: "options",
    options: [
      {
        name: "Yes",
        p: 4,
      },
      {
        name: "No",
        p: 5,
      },
    ],
  },
  {
    index: 20,
    name: "Please tell us what ear problems you have had",
    type: "text",
  },
  {
    index: 21,
    name: "Have you taken any medicines to help you with this ear problem?",
    type: "options",
    options: [
      {
        name: "Yes",
        p: 5,
      },
      {
        name: "No",
        p: 5,
      },
    ],
  },
  {
    index: 22,
    name: "Please tell us what medicines you have taken",
    type: "text",
  },
  {
    index: 23,
    name: "Are you allergic to anything?",
    type: "options",
    options: [
      {
        name: "Yes",
        p: 5,
      },
      {
        name: "No",
        p: 5,
      },
    ],
  },
  {
    index: 24,
    name: "Please tell us what allergies you have",
    type: "text",
  },
  {
    index: 25,
    name: "Is there anything else you would like to tell us?",
    type: "options",
    options: [
      {
        name: "Yes",
        p: 5,
      },
      {
        name: "No",
        p: 5,
      },
    ],
  },
  {
    index: 26,
    name: "Please tell us.",
    type: "text",
  },
];

export const ABDOMINAL_PAIN_QUESTIONS = [
  {
    Question_No: 0,
    Question: "Where is your pain? (pick all that apply)",
    Type: "Multi Options",
    Option: [
      { option: "In the middle", code: "P5", N_Question: 1 },
      { option: "Upper abdomen (tummy)", code: "P5", N_Question: 1 },
      { option: "Lower abdomen (tummy)", code: "P5", N_Question: 1 },
      { option: "Right side", code: "P5", N_Question: 1 },
      { option: "Left side", code: "P5", N_Question: 1 },
      { option: "All over", code: "P5", N_Question: 1 },
    ],
    N_Question: 1,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 1,
    Question: "How long have you had the pain?",
    Type: "Options",
    Option: [
      { option: "Less than 24hrs", code: "P4", N_Question: 2 },
      { option: "1 - 7 days", code: "P4", N_Question: 2 },
      { option: "1 - 4 weeks", code: "P5", N_Question: 2 },
      { option: "More than 1 month", code: "P5", N_Question: 2 },
    ],
    N_Question: 2,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 2,
    Question: "How would you describe your pain?",
    Type: "Options",
    Option: [
      { option: "Dull ache", code: "P5", N_Question: 4 },
      { option: "Sharp", code: "P3", N_Question: 4 },
      { option: "Stabbing", code: "P4", N_Question: 4 },
      { option: "Cramping", code: "P4", N_Question: 4 },
      { option: "Other", code: "P4", N_Question: 3 },
    ],
    N_Question: 3,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_N_Question: null,
  },

  {
    Question_No: 3,
    Question: "Describe the type of pain",
    Type: "Free text",
    N_Question: 4,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 4,
    Question: "Is the pain constant?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 5 },
      { option: "No", code: "P4", N_Question: 5 },
    ],
    N_Question: 5,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 5,
    Question: "How severe is your pain?",
    Type: "Options",
    Option: [
      {
        option: "The pain is there but I can carry on doing most things",
        code: "P4",
        N_Question: 6,
      },
      {
        option: "It is stopping me from doing most things",
        code: "P4",
        N_Question: 6,
      },
      {
        option: "I cannot cope with the pain and I am unable to do anything",
        code: "P3",
        N_Question: 6,
      },
    ],
    N_Question: 6,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 6,
    Question: "Does the pain move into your back?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 7 },
      { option: "No", code: "P5", N_Question: 7 },
    ],
    N_Question: 7,
    P_Question: -1,
    Condition: false,
    C_Question: "Age",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 7,
    Question: "Do you have chest pain?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 8 },
      { option: "No", code: "P5", N_Question: 8 },
    ],
    N_Question: 8,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 8,
    Question: "Are you short of breath?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 9 },
      { option: "No", code: "P5", N_Question: 9 },
    ],
    N_Question: 12,
    P_Question: -1,
    Condition: true,
    C_Question: "Gender",
    C_Type: "Female",
    C_N_Question: 9,
  },

  {
    Question_No: 9,
    Question: "Are you pregnant?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 10 },
      { option: "No", code: "P5", N_Question: 12 },
      { option: "Don't know", code: "P4", N_Question: 10 },
    ],
    N_Question: 10,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 10,
    Question: "Do you have any vaginal bleeding?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 11 },
      { option: "No", code: "P5", N_Question: 11 },
    ],
    N_Question: 11,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 11,
    Question: "Do you have pain in your shoulder?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 12 },
      { option: "No", code: "P5", N_Question: 12 },
    ],
    N_Question: 12,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 12,
    Question: "Do you have a temperature (more than 38°C or 100°F)?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 13 },
      { option: "No", code: "P5", N_Question: 13 },
      { option: "Don't know", code: "P4", N_Question: 13 },
    ],
    N_Question: 13,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 13,
    Question: "Do you feel cold?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 14 },
      { option: "No", code: "P5", N_Question: 14 },
    ],
    N_Question: 14,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 14,
    Question: "Have you vomited?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 15 },
      { option: "No", code: "P5", N_Question: 16 },
    ],
    N_Question: 15,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 15,
    Question: "Is there any blood in your vomit?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 16 },
      { option: "No", code: "P5", N_Question: 16 },
    ],
    N_Question: 16,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 16,
    Question: "Have you stopped eating or lost your appetite?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 17 },
      { option: "No", code: "P5", N_Question: 17 },
    ],
    N_Question: 17,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 17,
    Question: "Are you having diarrhoea?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 18 },
      { option: "No", code: "P5", N_Question: 18 },
    ],
    N_Question: 18,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 18,
    Question: "Is your stool a different colour than normal?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 19 },
      { option: "No", code: "P5", N_Question: 21 },
    ],
    N_Question: 19,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 19,
    Question: "What colour is your stool?",
    Type: "Options",
    Option: [
      { option: "It is black", code: "P2", N_Question: 21 },
      { option: "It is red", code: "P3", N_Question: 21 },
      { option: "It is another colour", code: "P3", N_Question: 20 },
    ],
    N_Question: 21,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "color",
    C_N_Question: null,
  },

  {
    Question_No: 20,
    Question: "Please tell us what colour is your stool (poo)?",
    Type: "Free text",
    N_Question: 21,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 21,
    Question: "Are you constipated?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 22 },
      { option: "No", code: "P5", N_Question: 22 },
    ],
    N_Question: 22,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 22,
    Question: "Are you having trouble passing urine?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 23 },
      { option: "No", code: "P5", N_Question: 23 },
    ],
    N_Question: 23,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 23,
    Question: "Does your abdomen feel hard?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 24 },
      { option: "No", code: "P5", N_Question: 24 },
    ],
    N_Question: 24,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 24,
    Question: "Is your abdomen swollen?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 25 },
      { option: "No", code: "P5", N_Question: 25 },
    ],
    N_Question: 25,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 25,
    Question: "Have you had recent abdominal surgery?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 26 },
      { option: "No", code: "P5", N_Question: 27 },
    ],
    N_Question: 27,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 26,
    Question:
      "Please tell us what operation you had, when and at what hospital",
    Type: "Free text",
    N_Question: 27,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 27,
    Question: "Have you had abdominal problems before?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 28 },
      { option: "No", code: "P5", N_Question: 29 },
    ],
    N_Question: 29,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 28,
    Question: "Please tell us about these abdominal (tummy) problems",
    Type: "Free text",
    N_Question: 29,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 29,
    Question: "Have you had recent unexpected weight loss?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 30 },
      { option: "No", code: "P5", N_Question: 30 },
    ],
    N_Question: 30,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 30,
    Question: "Have you taken any medication to help you with your pain?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 31 },
      { option: "No", code: "P4", N_Question: 32 },
    ],
    N_Question: 31,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 31,
    Question:
      "Please tell us what medications you have taken and what time you last took them",
    Type: "Free text",
    N_Question: 32,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 32,
    Question: "Are you allergic to anything?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 33 },
      { option: "No", code: "P5", N_Question: 34 },
    ],
    N_Question: 33,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 33,
    Question: "Please tell us what allergies to have",
    Type: "Free text",
    N_Question: 34,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 34,
    Question: "Do you take any regular medication?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 35 },
      { option: "No", code: "P5", N_Question: 36 },
    ],
    N_Question: 35,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 35,
    Question: "Please tell us what these medications are",
    Type: "Free text",
    N_Question: 36,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 36,
    Question: "Do you think you are at risk of domestic violence?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 37 },
      { option: "No", code: "P5", N_Question: 37 },
    ],
    N_Question: 37,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 37,
    Question: "Do you have any support from the following?",
    Type: "Options",
    Option: [
      { option: "Social Services", code: "P5", N_Question: 39 },
      { option: "Mental Health Services", code: "P5", N_Question: 39 },
      { option: "Other", code: "P5", N_Question: 38 },
      { option: "None", code: "P5", N_Question: 39 },
    ],
    N_Question: 39,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 38,
    Question: "Please tell us what services you have support from",
    Type: "Free text",
    N_Question: 39,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 39,
    Question: "Is there anything else you wish to tell us?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P0", N_Question: 40 },
      { option: "No", code: "P0", N_Question: 0 },
    ],
    N_Question: 40,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 40,
    Question: "Please tell us.",
    Type: "Free text",
    N_Question: 0,
    P_Question: 0,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
];

export const ASSAULT_CLINICAL_PATHWAY = [
  {
    Question_No: 0,
    Question: "Briefly describe what has happened",
    Type: "Free text",
    N_Question: 1,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 1,
    Question: "Are the police aware?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 2 },
      { option: "No", code: "P5", N_Question: 2 },
    ],
    N_Question: 2,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 2,
    Question: "Are you in danger?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 3 },
      { option: "No", code: "P5", N_Question: 3 },
    ],
    N_Question: 3,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },

  {
    Question_No: 3,
    Question: "Were any weapons used during the assault?",
    Type: "Options",
    Option: [
      { option: "Gun", code: "P1", N_Question: 5 },
      { option: "Knife", code: "P2", N_Question: 5 },
      { option: "Glass", code: "P3", N_Question: 5 },
      { option: "None", code: "P5", N_Question: 5 },
      { option: "Other", code: "", N_Question: 4 },
    ],
    N_Question: 5,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 4,
    Question: "Please tell us what weapons were used?",
    Type: "Free text",
    N_Question: 5,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 5,
    Question: "Do you have any head injuries?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 6 },
      { option: "No", code: "P5", N_Question: 7 },
    ],
    N_Question: 6,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 6,
    Question: "Did you fall to the floor after this head injury?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 7 },
      { option: "No", code: "P4", N_Question: 7 },
    ],
    N_Question: 7,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 7,
    Question: "Do you have any injuries to your face?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 8 },
      { option: "No", code: "P5", N_Question: 8 },
    ],
    N_Question: 8,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 8,
    Question: "Do you have any injuries to your chest?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 9 },
      { option: "No", code: "P5", N_Question: 9 },
    ],
    N_Question: 9,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 9,
    Question: "Do you have any injuries to your abdomen?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 10 },
      { option: "No", code: "P5", N_Question: 10 },
    ],
    N_Question: 10,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 10,
    Question: "Have you been sexually assaulted?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 11 },
      { option: "No", code: "P5", N_Question: 11 },
      { option: "Don't know", code: "P3", N_Question: 11 },
    ],
    N_Question: 11,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 11,
    Question: "Do you have any stab wounds (by any object)?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 12 },
      { option: "No", code: "P4", N_Question: 13 },
    ],
    N_Question: 12,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 12,
    Question: "Are the stab wounds only to your hands or feet?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 13 },
      { option: "No", code: "P2", N_Question: 13 },
    ],
    N_Question: 13,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 13,
    Question: "Have you been bitten during the assault?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 14 },
      { option: "No", code: "P5", N_Question: 14 },
    ],
    N_Question: 14,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 14,
    Question: "Do you have any bleeding you cannot stop?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 15 },
      { option: "No", code: "P4", N_Question: 15 },
    ],
    N_Question: 15,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 15,
    Question: "Have you vomited?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 16 },
      { option: "No", code: "P4", N_Question: 16 },
    ],
    N_Question: 16,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 16,
    Question: "Did you have any moments of being unconscious?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 17 },
      { option: "No", code: "P4", N_Question: 17 },
    ],
    N_Question: 17,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 17,
    Question: "Have you been assaulted by this person before?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 18 },
      { option: "No", code: "P4", N_Question: 18 },
    ],
    N_Question: 18,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 18,
    Question: "How severe is your pain?",
    Type: "Options",
    Option: [
      {
        option: "The pain is there but I can carry on doing most things",
        code: "P4",
        N_Question: 19,
      },
      {
        option: "It is stopping me from doing most things",
        code: "P4",
        N_Question: 19,
      },
      {
        option: "I cannot cope with the pain and I am unable to do anything",
        code: "P3",
        N_Question: 19,
      },
    ],
    N_Question: 19,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 19,
    Question: "Have you taken any medication to help with your pain?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 20 },
      { option: "No", code: "P5", N_Question: 21 },
    ],
    N_Question: 20,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 20,
    Question: "What medications have you taken and what time?",
    Type: "Free text",
    N_Question: 21,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 21,
    Question: "Are you allergic to anything?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 22 },
      { option: "No", code: "P5", N_Question: 23 },
    ],
    N_Question: 22,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 22,
    Question: "Please tell us what allergies you have?",
    Type: "Free text",
    N_Question: 23,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 23,
    Question: "Do you take blood thinning medication?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 24 },
      { option: "No", code: "P5", N_Question: 24 },
    ],
    N_Question: 24,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 24,
    Question: "Do you think you are at risk of domestic violence?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 25 },
      { option: "No", code: "P5", N_Question: 25 },
    ],
    N_Question: 25,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 25,
    Question: "Do you have any support from the following?",
    Type: "Options",
    Option: [
      { option: "Social Services", code: "P5", N_Question: 27 },
      { option: "Mental Health Services", code: "P5", N_Question: 27 },
      { option: "Other", code: "P5", N_Question: 26 },
      { option: "None", code: "P5", N_Question: 27 },
    ],
    N_Question: 26,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 26,
    Question: "Please tell us what services you have support from?",
    Type: "Free text",
    N_Question: 27,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 27,
    Question: "Were there any children present?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 28 },
      { option: "No", code: "P5", N_Question: 28 },
    ],
    N_Question: 28,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 28,
    Question: "Is there anything else you wish to tell us?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 29 },
      { option: "No", code: "P5", N_Question: 0 },
    ],
    N_Question: 29,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 29,
    Question: "Please tell us.",
    Type: "Free text",
    N_Question: 0,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
];

export const ANAL_PAIN_CLINICAL_PATHWAY = [
  {
    Question_No: 0,
    Question: "How long have you had anal pain?",
    Type: "Options",
    Option: [
      { option: "Less than 24hrs", code: "P4", N_Question: 1 },
      { option: "1 - 7 days", code: "P4", N_Question: 1 },
      { option: "1 - 4 weeks", code: "P5", N_Question: 1 },
      { option: "More than 1 month", code: "P5", N_Question: 1 },
    ],
    N_Question: 1,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 1,
    Question: "Have you had an injury to your anus?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 2 },
      { option: "No", code: "P5", N_Question: 3 },
    ],
    N_Question: 2,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 2,
    Question: "Have you had any bleeding since the injury?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 4 },
      { option: "No", code: "P5", N_Question: 4 },
    ],
    N_Question: 4,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 3,
    Question: "Have you had bleeding from the anus?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 4 },
      { option: "No", code: "P5", N_Question: 4 },
    ],
    N_Question: 4,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 4,
    Question:
      "Is there something in your anus that you think should not be there?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 5 },
      { option: "No", code: "P5", N_Question: 5 },
    ],
    N_Question: 5,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 5,
    Question: "Do you have a temperature (more than 38°C or 100°F)?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 6 },
      { option: "No", code: "P5", N_Question: 6 },
      { option: "Don't know", code: "P4", N_Question: 6 },
    ],
    N_Question: 6,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 6,
    Question: "Are you constipated?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 7 },
      { option: "No", code: "P5", N_Question: 7 },
    ],
    N_Question: 7,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 7,
    Question: "Do you have diarrhoea?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 8 },
      { option: "No", code: "P5", N_Question: 8 },
    ],
    N_Question: 8,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 8,
    Question: "Have you had any uxexplained recent weight loss?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 9 },
      { option: "No", code: "P5", N_Question: 9 },
    ],
    N_Question: 9,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 9,
    Question: "Do you think you have haemorrhoids?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 10 },
      { option: "No", code: "P5", N_Question: 10 },
    ],
    N_Question: 10,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 10,
    Question: "Have you had a recent change in bowel habit?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 11 },
      { option: "No", code: "P5", N_Question: 11 },
    ],
    N_Question: 11,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 11,
    Question: "How severe is your pain?",
    Type: "Options",
    Option: [
      {
        option: "The pain is there but I can carry on doing most things",
        code: "P4",
        N_Question: 12,
      },
      {
        option: "It is stopping me from doing most things",
        code: "P4",
        N_Question: 12,
      },
      {
        option: "I cannot cope with the pain and I am unable to do anything",
        code: "P3",
        N_Question: 12,
      },
    ],
    N_Question: 12,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 12,
    Question: "Do you think you have worms?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 13 },
      { option: "No", code: "P5", N_Question: 13 },
    ],
    N_Question: 13,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 13,
    Question: "Have you taken any medication for your pain?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 14 },
      { option: "No", code: "P5", N_Question: 15 },
    ],
    N_Question: 14,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 14,
    Question:
      "Please tell us what medications you have taken and when you last took them",
    Type: "Free text",
    N_Question: 15,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 15,
    Question: "Are you allergic to anything?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 16 },
      { option: "No", code: "P5", N_Question: 17 },
    ],
    N_Question: 16,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 16,
    Question: "Please tell us what allergies you have",
    Type: "Free text",
    N_Question: 17,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 17,
    Question: "Do you take any regular medication?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 18 },
      { option: "No", code: "P5", N_Question: 19 },
    ],
    N_Question: 18,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 18,
    Question: "Please tell us what these medications are",
    Type: "Free text",
    N_Question: 19,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 19,
    Question: "Do you think you are at risk of domestic violence?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 20 },
      { option: "No", code: "P5", N_Question: 20 },
    ],
    N_Question: 20,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 20,
    Question: "Do you have any support from the following?",
    Type: "Options",
    Option: [
      { option: "Social Services", code: "P5", N_Question: 22 },
      { option: "Mental Health Services", code: "P5", N_Question: 22 },
      { option: "Other", code: "P5", N_Question: 21 },
      { option: "None", code: "P5", N_Question: 22 },
    ],
    N_Question: 21,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 21,
    Question: "Please tell us what services you have support from",
    Type: "Free text",
    N_Question: 22,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 22,
    Question: "Is there anything else you wish to tell us?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 23 },
      { option: "No", code: "P5", N_Question: 0 },
    ],
    N_Question: 23,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 23,
    Question: "Please tell us.",
    Type: "Free text",
    N_Question: 0,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
];

export const ALLERGY_CLINICAL_PATHWAY = [
  {
    Question_No: 0,
    Question: "When did your symptoms start?",
    Type: "Options",
    Option: [
      { option: "Less than 24hrs", code: "P3", N_Question: 1 },
      { option: "1 - 7 days", code: "P4", N_Question: 1 },
      { option: "1 - 4 weeks", code: "P5", N_Question: 1 },
      { option: "More than 1 month", code: "P5", N_Question: 1 },
    ],
    N_Question: 1,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 1,
    Question: "Are you short of breath?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 2 },
      { option: "No", code: "P5", N_Question: 2 },
    ],
    N_Question: 2,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 2,
    Question: "Are you able to hear a wheeze when you breathe?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 3 },
      { option: "No", code: "P5", N_Question: 3 },
    ],
    N_Question: 3,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 3,
    Question: "Does your throat feel tight?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 4 },
      { option: "No", code: "P5", N_Question: 4 },
    ],
    N_Question: 4,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 4,
    Question: "Is your tongue swollen?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 5 },
      { option: "No", code: "P5", N_Question: 5 },
    ],
    N_Question: 5,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 5,
    Question: "Do you have swelling to your lips?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 6 },
      { option: "No", code: "P5", N_Question: 6 },
    ],
    N_Question: 6,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 6,
    Question: "Has the sound of your voice changed?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 7 },
      { option: "No", code: "P5", N_Question: 7 },
    ],
    N_Question: 7,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 7,
    Question: "Have you ever had anaphylaxis?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 8 },
      { option: "No", code: "P5", N_Question: 8 },
    ],
    N_Question: 8,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 8,
    Question: "Have you ever been prescribed an epipen?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 9 },
      { option: "No", code: "P5", N_Question: 10 },
    ],
    N_Question: 9,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 9,
    Question: "Have you used your epipen for this problem?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 10 },
      { option: "No", code: "P5", N_Question: 10 },
    ],
    N_Question: 9,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 10,
    Question: "Do you have a rash?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 11 },
      { option: "No", code: "P5", N_Question: 14 }, //////
    ],
    N_Question: 11,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 11,
    Question: "Does the rash feel itchy?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 12 },
      { option: "No", code: "P5", N_Question: 12 },
    ],
    N_Question: 12,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 12,
    Question: "Is the rash all over your body?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 14 },
      { option: "No", code: "P5", N_Question: 13 },
    ],
    N_Question: 13,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 13,
    Question: "Where is the Rash?",
    Type: "Free text",
    N_Question: 14,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 14,
    Question: "Is your heart beating faster than usual?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 15 },
      { option: "No", code: "P5", N_Question: 15 },
    ],
    N_Question: 15,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 15,
    Question: "Does your chest feel tight?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 16 },
      { option: "No", code: "P5", N_Question: 16 },
    ],
    N_Question: 16,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 16,
    Question: "Have you vomited?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 17 },
      { option: "No", code: "P5", N_Question: 18 },
    ],
    N_Question: 17,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 17,
    Question: "Is there blood in your vomit?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 18 },
      { option: "No", code: "P5", N_Question: 18 },
    ],
    N_Question: 18,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 18,
    Question: "Do you have any abdominal pain?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 19 },
      { option: "No", code: "P5", N_Question: 19 },
    ],
    N_Question: 19,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 19,
    Question: "Do you feel hot?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 20 },
      { option: "No", code: "P5", N_Question: 20 },
    ],
    N_Question: 20,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 20,
    Question: "Do you feel cold/shivery?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 21 },
      { option: "No", code: "P5", N_Question: 21 },
    ],
    N_Question: 21,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 21,
    Question:
      "Have you taken any medications (i.e.anti-allergy medications) for this problem?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 22 },
      { option: "No", code: "P5", N_Question: 23 },
    ],
    N_Question: 22,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 22,
    Question:
      "Which medications have you taken and what time did you take them?",
    Type: "Free text",
    N_Question: 23,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 23,
    Question: "Are you allergic to anything?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 24 },
      { option: "No", code: "P5", N_Question: 25 },
    ],
    N_Question: 24,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 24,
    Question: "Please tell us what allergies you have",
    Type: "Free text",
    N_Question: 25,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 25,
    Question: "Do you think you are at risk of domestic violence?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 26 },
      { option: "No", code: "P5", N_Question: 26 },
    ],
    N_Question: 26,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 26,
    Question: "Do you have any support from the following?",
    Type: "Options",
    Option: [
      { option: "Social Services", code: "P5", N_Question: 28 },
      { option: "Mental Health Services", code: "P5", N_Question: 28 },
      { option: "Other", code: "P5", N_Question: 27 },
      { option: "None", code: "P5", N_Question: 28 },
    ],
    N_Question: 27,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 27,
    Question: "Please tell us what services you have support from",
    Type: "Free text",
    N_Question: 28,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 28,
    Question: "Is there anything else you wish to tell us?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P0", N_Question: 29 },
      { option: "No", code: "P0", N_Question: 0 },
    ],
    N_Question: 29,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 29,
    Question: "Please tell us.",
    Type: "Free text",
    N_Question: 0,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
];

export const ARM_INJURD_PATHWAY = [
  {
    Question_No: 0,
    Question: "Which arm is injured?",
    Type: "Options",
    Option: [
      { option: "Right", code: "P5", N_Question: 1 },
      { option: "Left", code: "P5", N_Question: 1 },
      { option: "Both", code: "P5", N_Question: 1 },
    ],
    N_Question: 1,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 1,
    Question: "When did you hurt your arm?",
    Type: "Options",
    Option: [
      { option: "Less than 24 hours ago", code: "P4", N_Question: 2 },
      { option: "1-7 days ago", code: "P5", N_Question: 2 },
      { option: "1 to 4 weeks ago", code: "P5", N_Question: 2 },
      { option: "1 to 6 months ago", code: "P5", N_Question: 2 },
      { option: "More than 6 months ago", code: "P5", N_Question: 2 },
    ],
    N_Question: 2,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 2,
    Question: "Is your arm injury after a fall?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 3 },
      { option: "No", code: "P5", N_Question: 8 }, /////////////
    ],
    N_Question: 2,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 3,
    Question:
      "Did you fall from a height that is over 8 feet? (taller than a person/ second story window height)",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 4 },
      { option: "No", code: "P5", N_Question: 4 },
      { option: "Dont know", code: "P3", N_Question: 4 },
    ],
    N_Question: 4,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 4,
    Question: "Do you have any neck pain?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 5 },
      { option: "No", code: "P5", N_Question: 5 },
    ],
    N_Question: 5,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 5,
    Question: "Do you have any back pain?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 6 },
      { option: "No", code: "P5", N_Question: 6 },
    ],
    N_Question: 6,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 6,
    Question: "Do you have any other injuries?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 7 },
      { option: "No", code: "P5", N_Question: 14 }, //////////
    ],
    N_Question: 7,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 7,
    Question: "Please tell us what other injuries you may have?",
    Type: "Free text",
    N_Question: 14, ////////////
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 8,
    Question: "Did you injure your arm from any of the following?",
    Type: "Options",
    Option: [
      { option: "Road Accident", code: "P3", N_Question: 10 },
      { option: "Fainting/Collapsing", code: "P3", N_Question: 10 },
      { option: "Assault", code: "P4", N_Question: 10 },
      { option: "Other", code: "P5", N_Question: 9 },
    ],
    N_Question: 9,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 9,
    Question: "Please tell us how you injured your arm?",
    Type: "Free text",
    N_Question: 10,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 10,
    Question: "Do you have any neck pain?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 11 },
      { option: "No", code: "P5", N_Question: 11 },
    ],
    N_Question: 11,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 11,
    Question: "Do you have any back pain?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 12 },
      { option: "No", code: "P5", N_Question: 12 },
    ],
    N_Question: 12,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 12,
    Question: "Do you have any other injuries?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 13 },
      { option: "No", code: "P5", N_Question: 14 },
    ],
    N_Question: 13,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 13,
    Question: "Please tell us what other injuries you may have?",
    Type: "Free text",
    N_Question: 14,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 14,
    Question: "Was your arm crushed by force?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 15 },
      { option: "No", code: "P5", N_Question: 15 },
    ],
    N_Question: 15,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 15,
    Question: "Do you have any cuts (wounds) on the arm?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 16 },
      { option: "No", code: "P5", N_Question: 16 },
    ],
    N_Question: 16,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 16,
    Question: "Are you able to hold things with the injured arm?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 17 },
      { option: "No", code: "P4", N_Question: 17 },
    ],
    N_Question: 17,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 17,
    Question: "Is your arm warm to touch?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 19 },
      { option: "No", code: "P5", N_Question: 18 },
    ],
    N_Question: 18,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 18,
    Question: "Do you have a cold hand or arm?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 19 },
      { option: "No", code: "P5", N_Question: 19 },
    ],
    N_Question: 19,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 19,
    Question: "Has the injured arm or hand changed colour?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 20 },
      { option: "No", code: "P5", N_Question: 20 },
    ],
    N_Question: 20,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 20,
    Question: "Have you been able to straigten the injured arm?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 21 },
      { option: "No", code: "P3", N_Question: 21 },
    ],
    N_Question: 21,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 21,
    Question:
      "Have you had a high temperature (Do you have a temperature more than 38°C or 100°F) since you hurt your arm?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 22 },
      { option: "No", code: "P5", N_Question: 22 },
      { option: "Don't know", code: "P4", N_Question: 22 },
    ],
    N_Question: 22,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 22,
    Question: "Have you vomited?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 23 },
      { option: "No", code: "P5", N_Question: 24 },
    ],
    N_Question: 23,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 23,
    Question: "Is there blood in your vomit?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 24 },
      { option: "No", code: "P5", N_Question: 24 },
    ],
    N_Question: 24,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 24,
    Question: "Does your arm look like it is pointing the wrong way?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 25 },
      { option: "No", code: "P5", N_Question: 25 },
    ],
    N_Question: 25,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 25,
    Question: "Is your arm painful?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 26 },
      { option: "No", code: "P5", N_Question: 27 },
    ],
    N_Question: 26,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 26,
    Question: "How severe is your pain?",
    Type: "Options",
    Option: [
      {
        option: "The pain is there but I can carry on doing most things",
        code: "P4",
        N_Question: 27,
      },
      {
        option: "It is stopping me from doing most things",
        code: "P4",
        N_Question: 27,
      },
      {
        option: "I cannot cope with the pain and I am unable to do anything",
        code: "P3",
        N_Question: 27,
      },
    ],
    N_Question: 27,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 27,
    Question: "Do you have any bone conditions (i.e osteoporosis)?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 28 },
      { option: "No", code: "P5", N_Question: 28 },
    ],
    N_Question: 28,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 28,
    Question: "Have you taken any medication to help with this?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 29 },
      { option: "No", code: "P4", N_Question: 30 },
    ],
    N_Question: 29,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 29,
    Question:
      "Please tell us what medications you have taken and when you last took them?",
    Type: "Free text",
    N_Question: 30,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 30,
    Question: "Are you allergic to anything?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 31 },
      { option: "No", code: "P5", N_Question: 32 },
    ],
    N_Question: 31,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 31,
    Question: "Please tell us what allergies you have?",
    Type: "Free text",
    N_Question: 32,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 32,
    Question: "Do you take any regular medication?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 33 },
      { option: "No", code: "P5", N_Question: 34 },
    ],
    N_Question: 33,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 33,
    Question: "Please tell us what these medications are?",
    Type: "Free text",
    N_Question: 34,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 34,
    Question: "Do you think you are at risk of domestic violence?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 35 },
      { option: "No", code: "P5", N_Question: 35 },
    ],
    N_Question: 35,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 35,
    Question: "Do you have any support from the following?",
    Type: "Options",
    Option: [
      { option: "Social Services", code: "P5", N_Question: 37 },
      { option: "Mental Health Services", code: "P5", N_Question: 37 },
      { option: "Other", code: "P5", N_Question: 36 },
      { option: "None", code: "P5", N_Question: 37 },
    ],
    N_Question: 36,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 36,
    Question: "Please tell us what services you have support from?",
    Type: "Free text",
    N_Question: 37,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 37,
    Question: "Is there anything else you wish to tell us?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 38 },
      { option: "No", code: "P5", N_Question: 0 },
    ],
    N_Question: 38,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 38,
    Question: "Please tell us.",
    Type: "Free text",
    N_Question: 0,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
];

export const ANXIETY_PATHWAY = [
  {
    Question_No: 0,
    Question: "How long have you had the feeling of anxiety?",
    Type: "Options",
    Option: [
      { option: "Less than 24 hours", code: "P3", N_Question: 1 },
      { option: "1 - 7 days", code: "P3", N_Question: 1 },
      { option: "2 - 4 weeks", code: "P4", N_Question: 1 },
      { option: "1 - 6 months", code: "P5", N_Question: 1 },
      { option: "More than 6 months", code: "P5", N_Question: 1 },
    ],
    N_Question: 1,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 1,
    Question: "Does your throat feel tight?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 2 },
      { option: "No", code: "P5", N_Question: 2 },
    ],
    N_Question: 2,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 2,
    Question: "Are you short of breath?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 3 },
      { option: "No", code: "P5", N_Question: 3 },
    ],
    N_Question: 3,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 3,
    Question: "Do you have a diagnosis of anaemia?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 4 },
      { option: "No", code: "P5", N_Question: 4 },
    ],
    N_Question: 4,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 4,
    Question: "Do you feel your heart is beating too fast (palpitations)?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 5 },
      { option: "No", code: "P5", N_Question: 5 },
    ],
    N_Question: 5,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 5,
    Question: "Do you have chest pain?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 6 },
      { option: "No", code: "P5", N_Question: 6 },
    ],
    N_Question: 6,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 6,
    Question: "Are you breathing quickly?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 7 },
      { option: "No", code: "P5", N_Question: 7 },
    ],
    N_Question: 7,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 7,
    Question: "Have you had any recent unexpected weight loss?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 8 },
      { option: "No", code: "P5", N_Question: 8 },
    ],
    N_Question: 8,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 8,
    Question: "Do your hands or fingers feel different to usual?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 9 },
      { option: "No", code: "P5", N_Question: 9 },
    ],
    N_Question: 9,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 9,
    Question: "Have you had any recent physical injury?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 10 },
      { option: "No", code: "P5", N_Question: 11 },
    ],
    N_Question: 11,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 10,
    Question: "Please tell us about the injury",
    Type: "Free text",
    N_Question: 12,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 11,
    Question: "Have you experienced a recent traumatic psychological event?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 12 },
      { option: "No", code: "P5", N_Question: 12 },
    ],
    N_Question: 12,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 12,
    Question: "Have you recently started any new medication?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 13 },
      { option: "No", code: "P5", N_Question: 14 },
    ],
    N_Question: 13,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 13,
    Question: "Please tell us what these medications are",
    Type: "Free text",
    N_Question: 14,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 14,
    Question: "Have you recently stopped taking any regular medication/drugs?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 15 },
      { option: "No", code: "P5", N_Question: 16 },
    ],
    N_Question: 15,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 15,
    Question: "Please tell us what you have stopped and when",
    Type: "Free text",
    N_Question: 16,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 16,
    Question: "Have you experienced anxiety or panic attacks before?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 17 },
      { option: "No", code: "P3", N_Question: 18 },
    ],
    N_Question: 17,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 17,
    Question: "Please tell us about this and when this happened",
    Type: "Free text",
    N_Question: 18,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 18,
    Question: "Do you have any mental health illnesses?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 19 },
      { option: "No", code: "P5", N_Question: 20 },
    ],
    N_Question: 19,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 19,
    Question: "Please tell us about these",
    Type: "Free text",
    N_Question: 20,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 20,
    Question: "Are you feeling that you wish to harm yourself?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 21 },
      { option: "No", code: "P5", N_Question: 21 },
    ],
    N_Question: 21,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 21,
    Question: "Are you feeling suicidal?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 22 },
      { option: "No", code: "P5", N_Question: 22 },
    ],
    N_Question: 22,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 22,
    Question: "Have you taken any medication to help with this?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 23 },
      { option: "No", code: "P4", N_Question: 24 },
    ],
    N_Question: 23,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 23,
    Question:
      "Please tell us what medications you have taken and when you last took them",
    Type: "Free text",
    N_Question: 24,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 24,
    Question: "Are you allergic to anything?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 25 },
      { option: "No", code: "P5", N_Question: 26 },
    ],
    N_Question: 25,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 25,
    Question: "Please tell us what allergies you have",
    Type: "Free text",
    N_Question: 26,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 26,
    Question: "Do you take any regular medication?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 27 },
      { option: "No", code: "P5", N_Question: 28 },
    ],
    N_Question: 27,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 27,
    Question: "Please tell us what these medications are",
    Type: "Free text",
    N_Question: 28,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 28,
    Question: "Do you think you are at risk of domestic violence?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 29 },
      { option: "No", code: "P5", N_Question: 29 },
    ],
    N_Question: 29,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 29,
    Question: "Do you have any support from the following?",
    Type: "Options",
    Option: [
      { option: "Social Services", code: "P5", N_Question: 31 },
      { option: "Mental Health Services", code: "P5", N_Question: 31 },
      { option: "Other", code: "P5", N_Question: 30 },
      { option: "None", code: "P5", N_Question: 31 },
    ],
    N_Question: 30,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 30,
    Question: "Please tell us what services you have support from",
    Type: "Free text",
    N_Question: 31,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 31,
    Question: "Do you think you are at risk of domestic violence?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 32 },
      { option: "No", code: "P5", N_Question: 0 },
    ],
    N_Question: 0,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 32,
    Question: "Please tell us",
    Type: "Free text",
    N_Question: 0,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
];

export const HIP_PAIN_PATHWAY = [
  {
    Question_No: 0,
    Question: "When did the pain start?",
    Type: "Options",
    Option: [
      { option: "Less than 24 hours ago", code: "P3", N_Question: 1 },
      { option: "1 - 7 days ago", code: "P4", N_Question: 1 },
      { option: "1 - 4 weeks ago", code: "P4", N_Question: 1 },
      { option: "More than 1 month ago", code: "P5", N_Question: 1 },
    ],
    N_Question: 1,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 1,
    Question: "Have you had a recent injury to your hip or leg?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 2 },
      { option: "No", code: "P5", N_Question: 2 },
    ],
    N_Question: 2,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 2,
    Question: "Do you have any swelling around the hip?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 3 },
      { option: "No", code: "P5", N_Question: 3 },
    ],
    N_Question: 3,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 3,
    Question: "Do you also have some back pain?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 4 },
      { option: "No", code: "P5", N_Question: 4 },
    ],
    N_Question: 4,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 4,
    Question: "Have you collpased or felt faint today?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 5 },
      { option: "No", code: "P5", N_Question: 5 },
    ],
    N_Question: 5,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 5,
    Question: "Is the hip area warm to touch?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 6 },
      { option: "No", code: "P5", N_Question: 6 },
    ],
    N_Question: 6,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 6,
    Question: "Is the hip area cold to touch?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 7 },
      { option: "No", code: "P5", N_Question: 7 },
    ],
    N_Question: 7,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 7,
    Question: "Is the hip area tender to touch?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 8 },
      { option: "No", code: "P5", N_Question: 8 },
    ],
    N_Question: 8,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 8,
    Question: "Have you vomited?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 9 },
      { option: "No", code: "P5", N_Question: 9 },
    ],
    N_Question: 9,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 9,
    Question: "Do you have a history of hip trouble previously?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 10 },
      { option: "No", code: "P5", N_Question: 10 },
    ],
    N_Question: 10,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 10,
    Question: "Can you walk normally and without pain?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 11 },
      { option: "No", code: "P3", N_Question: 11 },
    ],
    N_Question: 11,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 11,
    Question: "Are you able to straighten the leg?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 12 },
      { option: "No", code: "P4", N_Question: 12 },
    ],
    N_Question: 12,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 12,
    Question: "Are you able to bend the leg?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 13 },
      { option: "No", code: "P4", N_Question: 13 },
    ],
    N_Question: 13,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 13,
    Question: "Is there discolouration to the leg?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 14 },
      { option: "No", code: "P5", N_Question: 14 },
    ],
    N_Question: 14,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 14,
    Question: "Does the pain wake you at night?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 15 },
      { option: "No", code: "P5", N_Question: 15 },
    ],
    N_Question: 15,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 15,
    Question: "Do you have a history of joint problems such as arthiritis?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 16 },
      { option: "No", code: "P5", N_Question: 16 },
    ],
    N_Question: 16,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 16,
    Question: "How how bad is your pain?",
    Type: "Options",
    Option: [
      {
        option: "The pain is there but I can carry on doing most things",
        code: "P4",
        N_Question: 17,
      },
      {
        option: "It is stopping me from doing most things",
        code: "P4",
        N_Question: 17,
      },
      {
        option: "I cannot cope with the pain and I am unable to do anything",
        code: "P3",
        N_Question: 17,
      },
    ],
    N_Question: 17,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 17,
    Question: "Have you taken any medication to help with your pain?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 18 },
      { option: "No", code: "P4", N_Question: 19 },
    ],
    N_Question: 18,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 18,
    Question:
      "Please tell us what medications you have taken and when you last took them",
    Type: "Free text",
    N_Question: 19,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 19,
    Question: "Are you allergic to anything?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 20 },
      { option: "No", code: "P5", N_Question: 21 },
    ],
    N_Question: 20,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 20,
    Question: "Please tell us what allergies you have",
    Type: "Free text",
    N_Question: 21,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 21,
    Question: "Do you take any regular medication?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 22 },
      { option: "No", code: "P5", N_Question: 23 },
    ],
    N_Question: 22,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 22,
    Question: "Please tell us what these medications are",
    Type: "Free text",
    N_Question: 23,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 23,
    Question: "Do you think you are at risk of domestic violence?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 24 },
      { option: "No", code: "P5", N_Question: 24 },
    ],
    N_Question: 24,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 24,
    Question: "Do you think you are at risk of domestic violence?",
    Type: "Options",
    Option: [
      { option: "Social Services", code: "P5", N_Question: 26 },
      { option: "Mental Health Services", code: "P5", N_Question: 26 },
      { option: "Other", code: "P5", N_Question: 25 },
      { option: "None", code: "P5", N_Question: 26 },
    ],
    N_Question: 25,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 25,
    Question: "Please tell us what services you have support from",
    Type: "Free text",
    N_Question: 26,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 26,
    Question: "Is there anything else you wish to tell us?",
    Type: "Free text",
    N_Question: 0,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
];

export const HAND_INJURY_PATHWAY = [
  {
    Question_No: 0,
    Question: "Which hand is injured?",
    Type: "Options",
    Option: [
      { option: "Right", code: "P5", N_Question: 1 },
      { option: "Left", code: "P5", N_Question: 1 },
      { option: "Both", code: "P5", N_Question: 1 },
    ],
    N_Question: 1,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 1,
    Question: "When did you hurt your hand?",
    Type: "Options",
    Option: [
      { option: "Less than 24 hours ago", code: "P4", N_Question: 2 },
      { option: "1 - 7 days ago", code: "P5", N_Question: 2 },
      { option: "1 - 4 weeks ago", code: "P5", N_Question: 2 },
      { option: "1 - 6 months ago", code: "P5", N_Question: 2 },
      { option: "More than 6 months ago", code: "P5", N_Question: 2 },
    ],
    N_Question: 2,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 2,
    Question: "Is your hand injury after a fall?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 3 },
      { option: "No", code: "P5", N_Question: 8 },
    ],
    N_Question: 3,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 3,
    Question:
      "Did you fall from a height that is over 8 feet? (taller than a person/second story window height)",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 4 },
      { option: "No", code: "P5", N_Question: 4 },
      { option: "Don't know", code: "P3", N_Question: 4 },
    ],
    N_Question: 4,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 4,
    Question: "Do you have any neck pain?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 5 },
      { option: "No", code: "P5", N_Question: 5 },
    ],
    N_Question: 5,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 5,
    Question: "Do you have any back pain?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 6 },
      { option: "No", code: "P5", N_Question: 6 },
    ],
    N_Question: 6,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 6,
    Question: "Do you have any other injuries?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 7 },
      { option: "No", code: "P5", N_Question: 14 },
    ],
    N_Question: 7,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 7,
    Question: "Please tell us what other injuries you may have",
    Type: "Free text",
    N_Question: 14,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 8,
    Question: "Do you have any other injuries?",
    Type: "Options",
    Option: [
      { option: "Road accident", code: "P3", N_Question: 10 },
      { option: "Fainting/collapsing", code: "P3", N_Question: 10 },
      { option: "Assault", code: "P4", N_Question: 10 },
      { option: "Other", code: "P5", N_Question: 9 },
    ],
    N_Question: 9,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 9,
    Question: "Please tell us how you injured your hand",
    Type: "Free text",
    N_Question: 10,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 10,
    Question: "Do you have any neck pain?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 11 },
      { option: "No", code: "P5", N_Question: 11 },
    ],
    N_Question: 11,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 11,
    Question: "Do you have any back pain?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 12 },
      { option: "No", code: "P5", N_Question: 12 },
    ],
    N_Question: 12,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 12,
    Question: "Do you have any other injuries?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 13 },
      { option: "No", code: "P5", N_Question: 14 },
    ],
    N_Question: 13,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 13,
    Question: "Please tell us what other injuries you may have",
    Type: "Free text",
    N_Question: 14,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 14,
    Question: "Was your hand crushed by force (squashed)?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 15 },
      { option: "No", code: "P5", N_Question: 15 },
    ],
    N_Question: 15,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 15,
    Question: "Do you have cuts (wounds) on the hand?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 16 },
      { option: "No", code: "P5", N_Question: 16 },
    ],
    N_Question: 16,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 16,
    Question: "Do you have active bleeding from the injury?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 17 },
      { option: "No", code: "P5", N_Question: 17 },
    ],
    N_Question: 17,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 17,
    Question: "Can you move the hand as normal?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 18 },
      { option: "No", code: "P3", N_Question: 18 },
    ],
    N_Question: 18,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 18,
    Question: "Are you able to hold things with the injured hand?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 19 },
      { option: "No", code: "P4", N_Question: 19 },
    ],
    N_Question: 19,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 19,
    Question: "Is your hand warm to touch?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 21 },
      { option: "No", code: "P5", N_Question: 20 },
    ],
    N_Question: 21,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 20,
    Question: "Do you have a cold hand or arm?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 21 },
      { option: "No", code: "P5", N_Question: 21 },
    ],
    N_Question: 21,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 21,
    Question: "Has the injured hand changed colour?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 22 },
      { option: "No", code: "P5", N_Question: 22 },
    ],
    N_Question: 22,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 22,
    Question: "Do you have a temperature (more than 38°C or 100°F)?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 23 },
      { option: "No", code: "P5", N_Question: 23 },
      { option: "Don't know", code: "P4", N_Question: 23 },
    ],
    N_Question: 23,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 23,
    Question: "Have you vomited?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 24 },
      { option: "No", code: "P5", N_Question: 25 },
    ],
    N_Question: 24,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 24,
    Question: "Is there blood in your vomit?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P2", N_Question: 25 },
      { option: "No", code: "P4", N_Question: 25 },
    ],
    N_Question: 25,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 25,
    Question: "Does your arm look like it is pointing the wrong way?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 26 },
      { option: "No", code: "P5", N_Question: 26 },
    ],
    N_Question: 26,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 26,
    Question: "Is your hand painful?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P3", N_Question: 27 },
      { option: "No", code: "P5", N_Question: 28 },
    ],
    N_Question: 27,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 27,
    Question: "How how bad is your pain?",
    Type: "Options",
    Option: [
      {
        option: "The pain is there but I can carry on doing most things",
        code: "P4",
        N_Question: 28,
      },
      {
        option: "It is stopping me from doing most things",
        code: "P4",
        N_Question: 28,
      },
      {
        option: "I cannot cope with the pain and I am unable to do anything",
        code: "P3",
        N_Question: 28,
      },
    ],
    N_Question: 28,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 28,
    Question: "Do you have any bone conditions (i.e osteoporosis)?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P4", N_Question: 29 },
      { option: "No", code: "P5", N_Question: 29 },
    ],
    N_Question: 29,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 29,
    Question: "Have you taken any medication to help with this?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 30 },
      { option: "No", code: "P4", N_Question: 31 },
    ],
    N_Question: 30,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 30,
    Question:
      "Please tell us what medication you have taken and when you last took it",
    Type: "Free text",
    N_Question: 31,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 31,
    Question: "Are you allergic to anything?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 32 },
      { option: "No", code: "P5", N_Question: 33 },
    ],
    N_Question: 32,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 32,
    Question: "Please tell us what allergies you have",
    Type: "Free text",
    N_Question: 33,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 33,
    Question: "Do you take any regular medication?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 34 },
      { option: "No", code: "P5", N_Question: 35 },
    ],
    N_Question: 34,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 34,
    Question: "Please tell us what these medications are",
    Type: "Free text",
    N_Question: 35,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 35,
    Question: "Do you think you are at risk of domestic violence?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 36 },
      { option: "No", code: "P5", N_Question: 36 },
    ],
    N_Question: 36,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 36,
    Question: "Do you have any support from the following?",
    Type: "Options",
    Option: [
      { option: "Social Services", code: "P5", N_Question: 38 },
      { option: "Mental Health Services", code: "P5", N_Question: 38 },
      { option: "Other", code: "P5", N_Question: 37 },
      { option: "None", code: "P5", N_Question: 38 },
    ],
    N_Question: 38,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 37,
    Question: "Please tell us what services you have support from",
    Type: "Free text",
    N_Question: 38,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 38,
    Question: "Is there anything else you wish to tell us?",
    Type: "Options",
    Option: [
      { option: "Yes", code: "P5", N_Question: 39 },
      { option: "No", code: "P5", N_Question: 0 },
    ],
    N_Question: 36,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
  {
    Question_No: 39,
    Question: "Please tell us",
    Type: "Free text",
    N_Question: 0,
    P_Question: -1,
    Condition: false,
    C_Question: "",
    C_Type: "",
    C_N_Question: null,
  },
];

export const COUNTRIES = [
  { country: "Afghanistan", code: "93", iso: "AF" },
  { country: "Albania", code: "355", iso: "AL" },
  { country: "Algeria", code: "213", iso: "DZ" },
  { country: "American Samoa", code: "1-684", iso: "AS" },
  { country: "Andorra", code: "376", iso: "AD" },
  { country: "Angola", code: "244", iso: "AO" },
  { country: "Anguilla", code: "1-264", iso: "AI" },
  { country: "Antarctica", code: "672", iso: "AQ" },
  { country: "Antigua and Barbuda", code: "1-268", iso: "AG" },
  { country: "Argentina", code: "54", iso: "AR" },
  { country: "Armenia", code: "374", iso: "AM" },
  { country: "Aruba", code: "297", iso: "AW" },
  { country: "Australia", code: "61", iso: "AU" },
  { country: "Austria", code: "43", iso: "AT" },
  { country: "Azerbaijan", code: "994", iso: "AZ" },
  { country: "Bahamas", code: "1-242", iso: "BS" },
  { country: "Bahrain", code: "973", iso: "BH" },
  { country: "Bangladesh", code: "880", iso: "BD" },
  { country: "Barbados", code: "1-246", iso: "BB" },
  { country: "Belarus", code: "375", iso: "BY" },
  { country: "Belgium", code: "32", iso: "BE" },
  { country: "Belize", code: "501", iso: "BZ" },
  { country: "Benin", code: "229", iso: "BJ" },
  { country: "Bermuda", code: "1-441", iso: "BM" },
  { country: "Bhutan", code: "975", iso: "BT" },
  { country: "Bolivia", code: "591", iso: "BO" },
  { country: "Bosnia and Herzegovina", code: "387", iso: "BA" },
  { country: "Botswana", code: "267", iso: "BW" },
  { country: "Brazil", code: "55", iso: "BR" },
  { country: "British Indian Ocean Territory", code: "246", iso: "IO" },
  { country: "British Virgin Islands", code: "1-284", iso: "VG" },
  { country: "Brunei", code: "673", iso: "BN" },
  { country: "Bulgaria", code: "359", iso: "BG" },
  { country: "Burkina Faso", code: "226", iso: "BF" },
  { country: "Burundi", code: "257", iso: "BI" },
  { country: "Cambodia", code: "855", iso: "KH" },
  { country: "Cameroon", code: "237", iso: "CM" },
  { country: "Canada", code: "1", iso: "CA" },
  { country: "Cape Verde", code: "238", iso: "CV" },
  { country: "Cayman Islands", code: "1-345", iso: "KY" },
  { country: "Central African Republic", code: "236", iso: "CF" },
  { country: "Chad", code: "235", iso: "TD" },
  { country: "Chile", code: "56", iso: "CL" },
  { country: "China", code: "86", iso: "CN" },
  { country: "Christmas Island", code: "61", iso: "CX" },
  { country: "Cocos Islands", code: "61", iso: "CC" },
  { country: "Colombia", code: "57", iso: "CO" },
  { country: "Comoros", code: "269", iso: "KM" },
  { country: "Cook Islands", code: "682", iso: "CK" },
  { country: "Costa Rica", code: "506", iso: "CR" },
  { country: "Croatia", code: "385", iso: "HR" },
  { country: "Cuba", code: "53", iso: "CU" },
  { country: "Curacao", code: "599", iso: "CW" },
  { country: "Cyprus", code: "357", iso: "CY" },
  { country: "Czech Republic", code: "420", iso: "CZ" },
  { country: "Democratic Republic of the Congo", code: "243", iso: "CD" },
  { country: "Denmark", code: "45", iso: "DK" },
  { country: "Djibouti", code: "253", iso: "DJ" },
  { country: "Dominica", code: "1-767", iso: "DM" },
  { country: "Dominican Republic", code: "1-809, 1-829, 1-849", iso: "DO" },
  { country: "East Timor", code: "670", iso: "TL" },
  { country: "Ecuador", code: "593", iso: "EC" },
  { country: "Egypt", code: "20", iso: "EG" },
  { country: "El Salvador", code: "503", iso: "SV" },
  { country: "Equatorial Guinea", code: "240", iso: "GQ" },
  { country: "Eritrea", code: "291", iso: "ER" },
  { country: "Estonia", code: "372", iso: "EE" },
  { country: "Ethiopia", code: "251", iso: "ET" },
  { country: "Falkland Islands", code: "500", iso: "FK" },
  { country: "Faroe Islands", code: "298", iso: "FO" },
  { country: "Fiji", code: "679", iso: "FJ" },
  { country: "Finland", code: "358", iso: "FI" },
  { country: "France", code: "33", iso: "FR" },
  { country: "French Polynesia", code: "689", iso: "PF" },
  { country: "Gabon", code: "241", iso: "GA" },
  { country: "Gambia", code: "220", iso: "GM" },
  { country: "Georgia", code: "995", iso: "GE" },
  { country: "Germany", code: "49", iso: "DE" },
  { country: "Ghana", code: "233", iso: "GH" },
  { country: "Gibraltar", code: "350", iso: "GI" },
  { country: "Greece", code: "30", iso: "GR" },
  { country: "Greenland", code: "299", iso: "GL" },
  { country: "Grenada", code: "1-473", iso: "GD" },
  { country: "Guam", code: "1-671", iso: "GU" },
  { country: "Guatemala", code: "502", iso: "GT" },
  { country: "Guernsey", code: "44-1481", iso: "GG" },
  { country: "Guinea", code: "224", iso: "GN" },
  { country: "Guinea-Bissau", code: "245", iso: "GW" },
  { country: "Guyana", code: "592", iso: "GY" },
  { country: "Haiti", code: "509", iso: "HT" },
  { country: "Honduras", code: "504", iso: "HN" },
  { country: "Hong Kong", code: "852", iso: "HK" },
  { country: "Hungary", code: "36", iso: "HU" },
  { country: "Iceland", code: "354", iso: "IS" },
  { country: "India", code: "91", iso: "IN" },
  { country: "Indonesia", code: "62", iso: "ID" },
  { country: "Iran", code: "98", iso: "IR" },
  { country: "Iraq", code: "964", iso: "IQ" },
  { country: "Ireland", code: "353", iso: "IE" },
  { country: "Isle of Man", code: "44-1624", iso: "IM" },
  { country: "Israel", code: "972", iso: "IL" },
  { country: "Italy", code: "39", iso: "IT" },
  { country: "Ivory Coast", code: "225", iso: "CI" },
  { country: "Jamaica", code: "1-876", iso: "JM" },
  { country: "Japan", code: "81", iso: "JP" },
  { country: "Jersey", code: "44-1534", iso: "JE" },
  { country: "Jordan", code: "962", iso: "JO" },
  { country: "Kazakhstan", code: "7", iso: "KZ" },
  { country: "Kenya", code: "254", iso: "KE" },
  { country: "Kiribati", code: "686", iso: "KI" },
  { country: "Kosovo", code: "383", iso: "XK" },
  { country: "Kuwait", code: "965", iso: "KW" },
  { country: "Kyrgyzstan", code: "996", iso: "KG" },
  { country: "Laos", code: "856", iso: "LA" },
  { country: "Latvia", code: "371", iso: "LV" },
  { country: "Lebanon", code: "961", iso: "LB" },
  { country: "Lesotho", code: "266", iso: "LS" },
  { country: "Liberia", code: "231", iso: "LR" },
  { country: "Libya", code: "218", iso: "LY" },
  { country: "Liechtenstein", code: "423", iso: "LI" },
  { country: "Lithuania", code: "370", iso: "LT" },
  { country: "Luxembourg", code: "352", iso: "LU" },
  { country: "Macao", code: "853", iso: "MO" },
  { country: "Macedonia", code: "389", iso: "MK" },
  { country: "Madagascar", code: "261", iso: "MG" },
  { country: "Malawi", code: "265", iso: "MW" },
  { country: "Malaysia", code: "60", iso: "MY" },
  { country: "Maldives", code: "960", iso: "MV" },
  { country: "Mali", code: "223", iso: "ML" },
  { country: "Malta", code: "356", iso: "MT" },
  { country: "Marshall Islands", code: "692", iso: "MH" },
  { country: "Mauritania", code: "222", iso: "MR" },
  { country: "Mauritius", code: "230", iso: "MU" },
  { country: "Mayotte", code: "262", iso: "YT" },
  { country: "Mexico", code: "52", iso: "MX" },
  { country: "Micronesia", code: "691", iso: "FM" },
  { country: "Moldova", code: "373", iso: "MD" },
  { country: "Monaco", code: "377", iso: "MC" },
  { country: "Mongolia", code: "976", iso: "MN" },
  { country: "Montenegro", code: "382", iso: "ME" },
  { country: "Montserrat", code: "1-664", iso: "MS" },
  { country: "Morocco", code: "212", iso: "MA" },
  { country: "Mozambique", code: "258", iso: "MZ" },
  { country: "Myanmar", code: "95", iso: "MM" },
  { country: "Namibia", code: "264", iso: "NA" },
  { country: "Nauru", code: "674", iso: "NR" },
  { country: "Nepal", code: "977", iso: "NP" },
  { country: "Netherlands", code: "31", iso: "NL" },
  { country: "Netherlands Antilles", code: "599", iso: "AN" },
  { country: "New Caledonia", code: "687", iso: "NC" },
  { country: "New Zealand", code: "64", iso: "NZ" },
  { country: "Nicaragua", code: "505", iso: "NI" },
  { country: "Niger", code: "227", iso: "NE" },
  { country: "Nigeria", code: "234", iso: "NG" },
  { country: "Niue", code: "683", iso: "NU" },
  { country: "North Korea", code: "850", iso: "KP" },
  { country: "Northern Mariana Islands", code: "1-670", iso: "MP" },
  { country: "Norway", code: "47", iso: "NO" },
  { country: "Oman", code: "968", iso: "OM" },
  { country: "Pakistan", code: "92", iso: "PK" },
  { country: "Palau", code: "680", iso: "PW" },
  { country: "Palestine", code: "970", iso: "PS" },
  { country: "Panama", code: "507", iso: "PA" },
  { country: "Papua New Guinea", code: "675", iso: "PG" },
  { country: "Paraguay", code: "595", iso: "PY" },
  { country: "Peru", code: "51", iso: "PE" },
  { country: "Philippines", code: "63", iso: "PH" },
  { country: "Pitcairn", code: "64", iso: "PN" },
  { country: "Poland", code: "48", iso: "PL" },
  { country: "Portugal", code: "351", iso: "PT" },
  { country: "Puerto Rico", code: "1-787, 1-939", iso: "PR" },
  { country: "Qatar", code: "974", iso: "QA" },
  { country: "Republic of the Congo", code: "242", iso: "CG" },
  { country: "Reunion", code: "262", iso: "RE" },
  { country: "Romania", code: "40", iso: "RO" },
  { country: "Russia", code: "7", iso: "RU" },
  { country: "Rwanda", code: "250", iso: "RW" },
  { country: "Saint Barthelemy", code: "590", iso: "BL" },
  { country: "Saint Helena", code: "290", iso: "SH" },
  { country: "Saint Kitts and Nevis", code: "1-869", iso: "KN" },
  { country: "Saint Lucia", code: "1-758", iso: "LC" },
  { country: "Saint Martin", code: "590", iso: "MF" },
  { country: "Saint Pierre and Miquelon", code: "508", iso: "PM" },
  { country: "Saint Vincent and the Grenadines", code: "1-784", iso: "VC" },
  { country: "Samoa", code: "685", iso: "WS" },
  { country: "San Marino", code: "378", iso: "SM" },
  { country: "Sao Tome and Principe", code: "239", iso: "ST" },
  { country: "Saudi Arabia", code: "966", iso: "SA" },
  { country: "Senegal", code: "221", iso: "SN" },
  { country: "Serbia", code: "381", iso: "RS" },
  { country: "Seychelles", code: "248", iso: "SC" },
  { country: "Sierra Leone", code: "232", iso: "SL" },
  { country: "Singapore", code: "65", iso: "SG" },
  { country: "Sint Maarten", code: "1-721", iso: "SX" },
  { country: "Slovakia", code: "421", iso: "SK" },
  { country: "Slovenia", code: "386", iso: "SI" },
  { country: "Solomon Islands", code: "677", iso: "SB" },
  { country: "Somalia", code: "252", iso: "SO" },
  { country: "South Africa", code: "27", iso: "ZA" },
  { country: "South Korea", code: "82", iso: "KR" },
  { country: "South Sudan", code: "211", iso: "SS" },
  { country: "Spain", code: "34", iso: "ES" },
  { country: "Sri Lanka", code: "94", iso: "LK" },
  { country: "Sudan", code: "249", iso: "SD" },
  { country: "Suriname", code: "597", iso: "SR" },
  { country: "Svalbard and Jan Mayen", code: "47", iso: "SJ" },
  { country: "Swaziland", code: "268", iso: "SZ" },
  { country: "Sweden", code: "46", iso: "SE" },
  { country: "Switzerland", code: "41", iso: "CH" },
  { country: "Syria", code: "963", iso: "SY" },
  { country: "Taiwan", code: "886", iso: "TW" },
  { country: "Tajikistan", code: "992", iso: "TJ" },
  { country: "Tanzania", code: "255", iso: "TZ" },
  { country: "Thailand", code: "66", iso: "TH" },
  { country: "Togo", code: "228", iso: "TG" },
  { country: "Tokelau", code: "690", iso: "TK" },
  { country: "Tonga", code: "676", iso: "TO" },
  { country: "Trinidad and Tobago", code: "1-868", iso: "TT" },
  { country: "Tunisia", code: "216", iso: "TN" },
  { country: "Turkey", code: "90", iso: "TR" },
  { country: "Turkmenistan", code: "993", iso: "TM" },
  { country: "Turks and Caicos Islands", code: "1-649", iso: "TC" },
  { country: "Tuvalu", code: "688", iso: "TV" },
  { country: "U.S. Virgin Islands", code: "1-340", iso: "VI" },
  { country: "Uganda", code: "256", iso: "UG" },
  { country: "Ukraine", code: "380", iso: "UA" },
  { country: "United Arab Emirates", code: "971", iso: "AE" },
  { country: "United Kingdom", code: "44", iso: "GB" },
  { country: "United States", code: "1", iso: "US" },
  { country: "Uruguay", code: "598", iso: "UY" },
  { country: "Uzbekistan", code: "998", iso: "UZ" },
  { country: "Vanuatu", code: "678", iso: "VU" },
  { country: "Vatican", code: "379", iso: "VA" },
  { country: "Venezuela", code: "58", iso: "VE" },
  { country: "Vietnam", code: "84", iso: "VN" },
  { country: "Wallis and Futuna", code: "681", iso: "WF" },
  { country: "Western Sahara", code: "212", iso: "EH" },
  { country: "Yemen", code: "967", iso: "YE" },
  { country: "Zambia", code: "260", iso: "ZM" },
  { country: "Zimbabwe", code: "263", iso: "ZW" },
];

export const monthList = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
]

export const convertMonthsToArabic = (months) => {
  const arabicMonths = [
    'يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو',
    'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'
  ];
  return months.map((month, i) => { return { monthName: arabicMonths[i], value: month } });
};

export const convertDateToArabic = (dateArray) => {
  const arabicDays = [
    '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩', '١٠', '١١', '١٢', '١٣', '١٤', '١٥',
    '١٦', '١٧', '١٨', '١٩', '٢٠', '٢١', '٢٢', '٢٣', '٢٤', '٢٥', '٢٦', '٢٧', '٢٨', '٢٩', '٣٠', '٣١'
  ];
  return dateArray.map((date) => { return { day: arabicDays[date - 1], value: date } });
};