import React, { useEffect, useState } from "react";
import { FormControl, Input, InputAdornment } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { PROBLEMS } from "../../Config/constants";
import { earProblem } from "../../redux/slices/earProblemSlice";
import { loading } from "../../redux/slices/loadingSlice";
import { problemType } from "../../redux/slices/problemTypeSlice";
import { clinicalPathway } from "../../APIs/earProblem";
import { pathwayQuestions } from "../../redux/slices/pathwayQuestionsSlice";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';

export default function TriageComponent() {
  const [problem, setProblem] = useState("");
  const [version, setVersion] = useState("");
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [bodyPartsSearch, setbodyPartsSearch] = useState("");
  const [bodyPartsFilterData, setBodyPartsFilterData] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [key, setKey] = useState("home");
  const usingLanguage = localStorage.getItem('i18nextLng')
  const clinicalProblems = useSelector((state) => state.clinicalProblems.value);
  const clinicalProblemsReason = useSelector(
    (state) => state.clinicalProblems.reason.injuryOrIllness
  );
  const { booking } = useSelector((state) => state.basicDetail);
  const staticDiseases =
    clinicalProblemsReason === "Illness"
      ? PROBLEMS.filter((d) => d.reason !== "Injury")
      : PROBLEMS.filter((d) => d.reason !== "Illness");
  const [allDeseases, setAllDiseases] = useState(clinicalProblems.a_z_list);
  const problemcontent = problem.multiLanguageDiseaseName?.length ? problem.multiLanguageDiseaseName.find((d) => (d.language === usingLanguage))?.content || problem.multiLanguageDiseaseName[0]?.content : problem.diseaseName;
  useEffect(() => {
    dispatch(loading({ percent: 50 }));
    dispatch(earProblem(null));
  }, []);

  useEffect(() => {
    if (booking.bookingFor === `${t('bookingForMySelf')}`) {
      setVersion("firstPerson");
    } else if (
      booking.bookingFor === `${t('bookingForSomeOneElse')}` &&
      booking.isBookingForChild
    ) {
      setVersion("paediatric");
    } else if (
      booking.bookingFor === `${t('bookingForSomeOneElse')}` &&
      !booking.isBookingForChild
    ) {
      setVersion("proxy");
    }
  }, [booking]);

  useEffect(() => {
    if (search === "") setFilteredData([]);
    else {
      filtering();
    }
  }, [search]);

  const filtering = () => {
    let _answers = allDeseases.filter((e) => {
      let result = handleDiseaseName(e)?.toLowerCase().includes(search.toLowerCase()) || e.tags.find((t) => t.toLowerCase().includes(search.toLowerCase()))
      return result
    });
    setFilteredData(_answers);
    if (_answers.length === 0) {
      toast.error(`${t('no_results_found')}`, "error");
    }
  };

  useEffect(() => {
    if (bodyPartsSearch === "") setBodyPartsFilterData([]);
    else {
      bodyPartsFiltering();
    }
  }, [bodyPartsSearch]);

  const bodyPartsFiltering = () => {
    let _answers = clinicalProblems.byBodyParts.filter((e) => {
      let result = e.name?.toLowerCase().includes(bodyPartsSearch.toLowerCase()) || e.tags.find((t) => t.toLowerCase().includes(bodyPartsSearch.toLowerCase()))
      return result
    }
    );
    setBodyPartsFilterData(_answers);
    if (_answers.length === 0) {
      toast.error(`${t('no_results_found')}`, "error");
    }
  };

  const handleProblemType = () => {
    return problem.multiLanguageDiseaseName?.length ? problem.multiLanguageDiseaseName.find((d) => (d.language === 'en'))?.content || problem.multiLanguageDiseaseName[0]?.content : problem.diseaseName
  }

  const handleNext = async () => {
		localStorage.setItem("diseaseId", problem._id);
    if (problem._id === 1) {
      dispatch(problemType({ problem: problem.diseaseName }));
      navigate("/clinical-pathway");
    } else if (problem._id !== 0) {
      if (problem.question.options.length) {
        navigate("/pre-pathway", { state: problem });
        dispatch(problemType({ problem: handleProblemType() }));
      } else {
        const data = {
          diseaseID: problem._id,
          version: version,
        };
        const pathway = await clinicalPathway(data);
        if (pathway) {
          console.log("pathway", pathway);
          dispatch(pathwayQuestions(pathway));
          dispatch(problemType({ problem: handleProblemType() }));
          navigate("/pathway", { state: { showBadPainImages: false } });
        }
      }
    }
  };

  const handlePrevious = () => {
    navigate("/pre-ecds-questions");
  };

  var handleDiseaseName = (e) => {
    return e.multiLanguageDiseaseName?.length ? e.multiLanguageDiseaseName.find((d) => d.language === usingLanguage)?.content || e.multiLanguageDiseaseName[0]?.content : e.diseaseName
  }

  return (
    <div className="ecds-container">
      <div className="content-div">
        <img
          src={require("../../Assets/Images/logo.png")}
          className="logo-smaller"
          alt="logo"
        />
        <div className="arrow-left">
          <img
            className="arrow-left-img"
            src={require("../../Assets/Images/arrow-left.png")}
            alt="arrow"
            onClick={handlePrevious}
          />
        </div>

        <div className="question">
          {t('question_heading_1')}
        </div>
        <div className="pre-triage body-tabs">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => {
              if (key === k && k == "home") {
                setAllDiseases(clinicalProblems.a_z_list);
              }
              setKey(k);
            }}
            className="mb-3"
          >
            <Tab eventKey="home" title={t('azlist')}>
              <div className="">
                <FormControl className="answer-multiple">
                  <Input
                    disableUnderline
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ fontSize: 12, marginTop: 3 }}
                    sx={{ input: { color: "white" } }}
                    value={search}
                    placeholder={t('search')}
                    startAdornment={
                      <InputAdornment position="start">
                        <img
                          src={require("../../Assets/Images/searchIcon.png")}
                          className="logo-smallest"
                        />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div className="scroll-visible">
                {(filteredData.length === 0 && search
                  ? []
                  : filteredData.length === 0
                    ? allDeseases
                    : filteredData
                )?.map((e) => (
                  <div
                    onClick={() => setProblem(e)}
                    className={
                      problemcontent === handleDiseaseName(e)
                        ? "active-option answer-div"
                        : "inactive-option answer-div"
                    }
                  >
                    {handleDiseaseName(e)}
                  </div>
                ))}
              </div>
            </Tab>
            <Tab eventKey="profile" title={t('by_body_part')}>
              <div className="body-parts">
                <FormControl className="answer-multiple">
                  <Input
                    disableUnderline
                    onChange={(e) => setbodyPartsSearch(e.target.value)}
                    style={{ fontSize: 12, marginTop: 3 }}
                    sx={{ input: { color: "white" } }}
                    value={bodyPartsSearch}
                    placeholder={t('search')}
                    startAdornment={
                      <InputAdornment position="start">
                        <img
                          src={require("../../Assets/Images/searchIcon.png")}
                          className="logo-smallest"
                        />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <div className="scroll-visible card-scroll">
                  {(bodyPartsFilterData.length === 0 && bodyPartsSearch
                    ? []
                    : bodyPartsFilterData.length === 0
                      ? clinicalProblems.byBodyParts
                      : bodyPartsFilterData
                  )?.map((d) => {
                    return (
                      <div className="card">
                        <div className="parts-img">
                          <img src={`${d.url}`} alt="head" />
                        </div>
                        <div
                          className="text-center cursor-pointer"
                          onClick={() => {
                            setAllDiseases(d.a_z_list);
                            setKey("home");
                          }}
                        >
                          <h3>{d.multiLanguageName?.length ? d.multiLanguageName.find((d) => d.language === usingLanguage)?.content || d.multiLanguageName[0]?.content : d.name}</h3>
                          <p>{d.multiLanguageInfo?.length ? d.multiLanguageInfo.find((d) => d.language === usingLanguage)?.content || d.multiLanguageInfo[0]?.content : d.info}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
      <button className="button-ecds-next br py-2" onClick={handleNext}>
        <span>{t('next')}</span>
      </button>
    </div>
  );
}
